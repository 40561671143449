/**
 * async version of setTimeout.
 *
 * ```ts
 * await asyncTimeout(1000)
 * doSomething()
 * ```
 */

export function delay(duration: number) {
  return new Promise((resolve) => setTimeout(resolve, duration))
}
