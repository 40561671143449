import { useCouponStore } from '../stores/useCouponStore'

export default defineNuxtPlugin({
  order: 11,
  async setup() {
    const couponStore = useCouponStore()
    const { $marketsStore } = useNuxtApp()
    couponStore.setMarketsStore($marketsStore)
  },
})
