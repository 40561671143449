import Outcome from './Outcome'
import IndexedArray from './IndexedArray'
import { isVue2 } from 'vue-demi'
import { shallowReactive } from 'vue'
const specSet = new Set(['total', 'hcp', ' hcp', 'hcp '])

const parseSpecifiersString = function (str) {
  const specifiers = {}
  let spParam = ''
  if (typeof str !== 'string') return { specifiers, spParam }
  if (str[0] === '{') return JSON.parse(str)
  for (const specifierString of str.split('|')) {
    const _val = specifierString.split('=')[1]
    const _key = specifierString.split('=')[0]
    if (!spParam) {
      spParam = specSet.has(_key)
        ? !(_val % 0.5)
          ? 'half'
          : !(_val % 1)
            ? 'ceil'
            : 'asian'
        : ''
    }
    specifiers[_key] =
      _key === 'total' || _key === 'hcp'
        ? !isNaN(_val)
          ? Number(_val)
          : String(_val)
        : _val
  }
  return {
    specifiers,
    spParam,
  }
}

const getRange = function (market) {
  return Math.abs(market.outcomes[0].odds - market.outcomes[1].odds)
}

const getMarketStatus = function ({ sbStatus, state, eventState }) {
  const statuses = {
    '-100': 0,
    '-20': -1,
    '-11': -1,
    '-10': -1,
    0: 0,
  }
  if (!sbStatus) return 0
  const statusByState =
    typeof eventState !== 'undefined' && eventState < 0
      ? statuses[String(eventState)]
      : statuses[String(state)]
  if (typeof statusByState !== 'undefined') return statusByState
  return sbStatus
}

/**
 * dynamic properties
 */
const customConfigurableValues = {
  favourite: true,
  name: true,
  outcomes: true,
  provider: true,
  state: true,
  status: true,
  timestamp: true,
  weight: true,
}

export default function (
  {
    id,
    name = '',
    outcomes = [],
    specifiers = '',
    specifiersString = '',
    spParam = '',
    status,
    favourite = null,
    state,
    weight,
    timestamp,
    provider,
  },
  event,
) {
  const eventId = event.id
  const type = event.type
  const eventState = event.state
  const market = {
    id: Number(id),
    sbStatus: status,
    state,
    favourite,
    eventState,
    name,
    eventId,
    weight,
    timestamp,
    provider,
  }
  market.status = getMarketStatus(market)

  if (typeof specifiers === 'string') {
    market.specifiersString = specifiers
    const tmpSp = parseSpecifiersString(specifiers)
    market.specifiers = tmpSp.specifiers
    market.spParam = tmpSp.spParam
  } else {
    market.specifiers = specifiers
    market.specifiersString = specifiersString
    market.spParam = spParam
  }

  market.outcomes = new IndexedArray(
    outcomes.map(
      (outc) =>
        new Outcome(outc, {
          marketId: id,
          specifiersString: market.specifiersString,
          eventId,
          type,
        }),
    ),
  )

  if (market.spParam && outcomes.length === 2) {
    market.outcomesRange = getRange(market)
  }

  return new Proxy(isVue2 ? market : shallowReactive(market), {
    get(target, propKey, receiver) {
      switch (propKey) {
        case 'getOwnPropertyDescriptorModified':
          return (target, prop) => {
            const descriptorValue = Reflect.getOwnPropertyDescriptor(
              target,
              prop,
            )
            const configurable = customConfigurableValues[prop]
            if (descriptorValue && !configurable) {
              descriptorValue.configurable = false
            }
            return descriptorValue
          }
        case 'update':
          return (
            { favourite, name, outcomes, provider, status, state, timestamp },
            eventState,
            force = false,
            oddsChangeVisualHandler,
          ) => {
            if (typeof timestamp === 'string') {
              timestamp = new Date(timestamp).getTime()
            }
            if (timestamp < target.timestamp && provider === target.provider)
              return

            target.timestamp = timestamp
            if (
              typeof eventState !== 'undefined' &&
              target.eventState !== eventState
            ) {
              target.eventState = eventState
            }

            if (typeof state !== 'undefined' && target.state !== state) {
              target.state = state
            }

            if (
              !force &&
              typeof status !== 'undefined' &&
              target.sbStatus !== status
            ) {
              target.sbStatus = status
            }

            if (
              typeof favourite !== 'undefined' &&
              target.favourite !== favourite
            ) {
              target.favourite = favourite
            }

            if (!target.name && name) {
              target.name = name
            }

            if (
              typeof provider !== 'undefined' &&
              target.provider !== provider
            ) {
              target.provider = provider
            }

            const newStatus = getMarketStatus(target)
            if (target.status !== newStatus) {
              target.status = newStatus
            }
            if (outcomes && outcomes.length) {
              outcomes.forEach((newData) => {
                let oldData = target.outcomes.findById(newData.id)
                if (Array.isArray(oldData)) {
                  oldData = oldData.find((o) => !isNaN(o.odds))
                }
                if (oldData) {
                  oldData.update(newData, oddsChangeVisualHandler)
                } else {
                  const newOutcome = new Outcome(newData, {
                    marketId: target.id,
                    specifiersString: target.specifiersString,
                    eventId: target.eventId,
                    type: 'match',
                  })
                  target.outcomes.push(newOutcome)
                }
              })
            }
          }
        case 'updateTitle':
          return (title = '') => {
            target.title = title
            return title
          }
        case 'getOriginalStructure':
          return () => {
            return {
              ...target,
              specifiers: target.specifiersString,
              status: target.sbStatus,
            }
          }
        default:
          return Reflect.get(target, propKey, receiver)
      }
    },
  })
}
