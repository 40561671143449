import type { TMarket } from 'markets-store'
import type { MarketStoreInstance } from 'markets-store/factory/types'

interface AddNamesToMarketParams {
  marketsStore: MarketStoreInstance
  market: TMarket
}

/**
 * По идее эта функция добавляет в маркет и его ауткамы имена.
 * Но вообще это какое-то колдунство.
 */
export async function addNamesToMarket({
  marketsStore,
  market,
}: AddNamesToMarketParams): Promise<void> {
  const { client, lang } = marketsStore

  const event = marketsStore.sportEvents.getById(market.eventId)
  if (!event) return

  // @ts-expect-error fuck marketsStore
  const origStructMarket = market.getOriginalStructure()
  const marketToResolve = { market: origStructMarket, lang }
  // @ts-expect-error fuck marketsStore
  const resolvedMarketName = await client.resolveMarket(marketToResolve)

  // @ts-expect-error fuck marketsStore
  const marketName = await client.resolveMarketOrOutcomeName({
    name: resolvedMarketName,
    market: { id: market.id },
    sportEvent: event,
    lang,
    fallbackLang: 'en',
  })

  // @ts-expect-error fuck marketsStore
  market.update({ name: marketName })

  market.outcomes.forEach(async (outcome) => {
    // @ts-expect-error fuck marketsStore
    const resolvedOutcomeName = await client.resolveOutcome({
      outcomeId: outcome.id,
      type: outcome.type,
      competitors: event.competitors.map((cp) => ({
        ...cp,
        name: {
          [lang]: cp.name,
        },
      })),
      lang,
    })
    // @ts-expect-error fuck marketsStore
    const outcomeName = await client.resolveMarketOrOutcomeName({
      name: resolvedOutcomeName,
      market: origStructMarket,
      sportEvent: event,
      lang,
    })

    // @ts-expect-error fuck marketsStore
    outcome.updateName(outcomeName)
  })
}
