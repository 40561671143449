import { useUserStore } from '@st/user/stores/useUserStore'
import { delay } from '@st/utils'
import type { Notification, SocketNotification } from '../types'
import { NOTIFICATION_DURATION } from '../constants'

export const useNotificationsStore = defineStore('notifications', () => {
  const stFetch = useRawStFetch()
  const { isAuthenticated } = storeToRefs(useUserStore())

  const { data, execute: fetchActiveNotificationsCounters } = useStFetch(
    '/user-notification/find',
    {
      method: 'post',
      body: {
        params: {
          read: false,
        },
        pagination: {
          page: 0,
          perPage: 1,
          orderBy: [{ fieldName: 'createdAt', sortOrder: 'DESC' }],
        },
      },
      immediate: false,
      watch: false,
    },
  )

  const activeNotificationsCounter = ref(0)
  const notificationsAlerts = ref<Notification[]>([])

  async function updateNotificationsCounters() {
    if (isAuthenticated.value) {
      await fetchActiveNotificationsCounters()
      activeNotificationsCounter.value = data.value?.paging.total ?? 0
    } else activeNotificationsCounter.value = 0
  }
  async function openNotificationAlert(item: SocketNotification) {
    const { userNotificationId: id, read, type, data: notificationData } = item
    const normalizedItem = {
      id,
      createdAt: '',
      read,
      type,
      data: notificationData,
      fileStorageId: null,
    } as Notification
    notificationsAlerts.value.unshift(normalizedItem)
    await nextTick()
    await delay(NOTIFICATION_DURATION)
    notificationsAlerts.value.pop()
  }
  function closeNotificationAlert(id: number) {
    notificationsAlerts.value = notificationsAlerts.value.filter(
      (item) => item.id !== id,
    )
  }

  async function readNotification(id: number) {
    try {
      await stFetch('/user-notification/read', {
        method: 'post',
        body: {
          ids: [id],
        },
      })
      closeNotificationAlert(id)
      await updateNotificationsCounters()
    } catch (error) {
      console.error('Failed to read notification')
    }
  }

  async function readAllNotifications() {
    try {
      await stFetch('/user-notification/read/all', {
        method: 'post',
      })
      await updateNotificationsCounters()
    } catch (error) {
      console.error('Failed to read notification')
    }
  }

  /**
   * запрашивает каунтеры при наличии юзера
   * сбрасывает в 0 при отсутствии
   */
  watch(
    () => isAuthenticated.value,
    () => {
      updateNotificationsCounters()
    },
    { immediate: true },
  )

  const io = useSocket()
  io.on('userNotification', async (payload: SocketNotification) => {
    await openNotificationAlert(payload)
    await updateNotificationsCounters()
  })

  return {
    activeNotificationsCounter,
    notificationsAlerts,
    readNotification,
    readAllNotifications,
    openNotificationAlert,
  }
})
