class MapWithDefaults<K, V> extends Map<K, V> {
  private defaultValue: V

  constructor(defaultValue: V) {
    super()
    this.defaultValue = defaultValue
  }

  get(key: K): V {
    if (this.has(key)) {
      return super.get(key)!
    } else {
      return this.defaultValue
    }
  }
}

export function groupBy<T, K>(
  items: T[],
  callback: (item: T, index: number, array: T[]) => K,
): MapWithDefaults<K, T[]> {
  return items.reduce((result, item, index, array) => {
    const value = callback(item, index, array)
    if (result.has(value)) {
      result.get(value)!.push(item)
    } else {
      result.set(value, [item])
    }
    return result
  }, new MapWithDefaults<K, T[]>([]))
}
