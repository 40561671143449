import { isVue2, reactive } from 'vue-demi'

const IndexedActual = new Proxy(Array, {
  construct(Target, [args]) {
    const indexId = {}
    const live = reactive([])
    const prematch = reactive([])
    const byTournamentId = {}
    const byCategoryId = {}
    const bySportId = {}
    const liveSportIdsCounter = new Map()
    const liveSportIdsWithVideoCounter = new Map()
    const liveCategoryIdsCounter = new Map()
    const liveTournamentIdsCounter = new Map()

    function setInCache(event) {
      if (!event) return
      const tournamentId = event.tournamentId || 0
      const categoryId = event.categoryId || 0
      const sportId = event.sportId || 0

      indexId[event.id] = event

      if ((event.dicePlatformId || event.video) && event.status === 1) {
        addCountersVideo(sportId, categoryId, tournamentId)
      }

      if (event.status === 1) {
        live.push(event)
        addCounters(sportId, categoryId, tournamentId)
      } else if (!event.status) {
        prematch.push(event)
      }

      if (tournamentId) {
        if (!byTournamentId[tournamentId]) {
          byTournamentId[tournamentId] = []
        }
        byTournamentId[tournamentId].push(event)
      }
      if (categoryId) {
        if (!byCategoryId[categoryId]) {
          byCategoryId[categoryId] = []
        }
        byCategoryId[categoryId].push(event)
      }
      if (sportId) {
        if (!bySportId[sportId]) {
          bySportId[sportId] = []
        }
        bySportId[sportId].push(event)
      }
    }

    function addCounters(sportId, categoryId, tournamentId) {
      if (!liveSportIdsCounter.has(sportId)) {
        liveSportIdsCounter.set(sportId, 0)
      }
      const countSport = liveSportIdsCounter.get(sportId)
      liveSportIdsCounter.set(sportId, countSport + 1)

      if (!liveCategoryIdsCounter.has(categoryId)) {
        liveCategoryIdsCounter.set(categoryId, 0)
      }
      const countCategory = liveCategoryIdsCounter.get(categoryId)
      liveCategoryIdsCounter.set(categoryId, countCategory + 1)

      if (!liveTournamentIdsCounter.has(tournamentId)) {
        liveTournamentIdsCounter.set(tournamentId, 0)
      }
      const countTournament = liveTournamentIdsCounter.get(tournamentId)
      liveTournamentIdsCounter.set(tournamentId, countTournament + 1)
    }

    function addCountersVideo(sportId, categoryId, tournamentId) {
      if (!liveSportIdsWithVideoCounter.has(sportId)) {
        liveSportIdsWithVideoCounter.set(sportId, 0)
      }
      const countSport = liveSportIdsWithVideoCounter.get(sportId)
      liveSportIdsWithVideoCounter.set(sportId, countSport + 1)
    }

    function moveToLive(id) {
      const index = prematch.findIndex((e) => e.id === id)
      if (!~index) return
      const [event] = prematch.splice(index, 1)
      if (event) {
        live.push(event)

        const tournamentId = event.tournamentId || 0
        const categoryId = event.categoryId || 0
        const sportId = event.sportId || 0

        addCounters(sportId, categoryId, tournamentId)

        if (event.dicePlatformId || event.video) {
          addCountersVideo(sportId, categoryId, tournamentId)
        }
      }
    }

    function removeFromCache(event) {
      const eventId = event.id
      const inTournament = byTournamentId[event.tournament_id]
      const inCategory = byCategoryId[event.category_id]
      const inSport = bySportId[event.sport_id]

      if (inTournament && inTournament.length) {
        const inTournamentIndex = inTournament.findIndex(
          (e) => e.id === eventId,
        )
        if (~inTournamentIndex) {
          byTournamentId[event.tournament_id].splice(inTournamentIndex, 1)
        }
      }

      if (inCategory && inCategory.length) {
        const inCategoryIndex = inCategory.findIndex((e) => e.id === eventId)
        if (~inCategoryIndex) {
          byCategoryId[event.category_id].splice(inCategoryIndex, 1)
        }
      }

      if (inSport && inSport.length) {
        const inSportIndex = inSport.findIndex((e) => e.id === eventId)
        if (~inSportIndex) {
          bySportId[event.sport_id].splice(inSportIndex, 1)
        }
      }

      const liveIndex = live.findIndex((e) => e.id === eventId)
      if (~liveIndex) {
        live.splice(liveIndex, 1)

        const tournamentId = event.tournament_id || 0
        const categoryId = event.category_id || 0
        const sportId = event.sport_id || 0

        if (liveSportIdsCounter.has(sportId)) {
          const countSport = liveSportIdsCounter.get(sportId)
          if (countSport) {
            liveSportIdsCounter.set(sportId, countSport - 1)
          }
        }

        if (event.dicePlatformId || event.video) {
          if (liveSportIdsWithVideoCounter.has(sportId)) {
            const countSport = liveSportIdsWithVideoCounter.get(sportId)
            if (countSport) {
              liveSportIdsWithVideoCounter.set(sportId, countSport - 1)
            }
          }
        }

        if (liveCategoryIdsCounter.has(categoryId)) {
          const countCategory = liveCategoryIdsCounter.get(categoryId)
          if (countCategory) {
            liveCategoryIdsCounter.set(categoryId, countCategory - 1)
          }
        }

        if (liveTournamentIdsCounter.has(tournamentId)) {
          const countTournament = liveTournamentIdsCounter.get(tournamentId)
          if (countTournament) {
            liveTournamentIdsCounter.set(tournamentId, countTournament - 1)
          }
        }
      }

      const prematchIndex = prematch.findIndex((e) => e.id === eventId)
      if (~prematchIndex) {
        prematch.splice(prematchIndex, 1)
      }
    }

    for (let i = args.length - 1; i >= 0; --i) {
      const event = args[i]
      setInCache(event)
    }

    return new Proxy(
      isVue2 ? new Target(...args) : reactive(new Target(...args)),
      {
        get(arr, prop) {
          switch (prop) {
            case 'push':
              return (...items) => {
                arr[prop].call(arr, ...items) // eslint-disable-line
                for (const item of items) {
                  if (!indexId[item.id]) {
                    setInCache(item)
                  }
                }
              }
            case 'removeById':
              return (id) => {
                const event = indexId[id]
                if (!event) return
                removeFromCache(event)
                delete indexId[id]
                const index = arr.findIndex((e) => e.id === id)
                if (~index) {
                  arr.splice(index, 1)
                }
              }
            case 'moveToLive':
              return (id) => {
                moveToLive(id)
                arr.splice(0, 0)
              }
            case 'getById':
              return (id) => {
                return indexId[id]
              }
            case 'getByIds':
              return (ids) => {
                const events = []
                for (const id of ids) {
                  if (indexId[id]) events.push(indexId[id])
                }
                return events
              }
            case 'live':
              return live
            case 'prematch':
              return prematch
            case 'prematchByStartTime':
              return (ms) =>
                prematch.filter((ev) => ev.scheduled <= Date.now() + ms)
            case 'allByStartTime':
              return (ms) =>
                live
                  .concat(prematch)
                  .filter((ev) => ev.scheduled <= Date.now() + ms)
            case 'bySportId':
              return (sportId) => {
                if (!bySportId[sportId]) {
                  bySportId[sportId] = []
                }
                return bySportId[sportId]
              }
            case 'byCategoryId':
              return (categoryId) => {
                if (!byCategoryId[categoryId]) {
                  byCategoryId[categoryId] = []
                }
                return byCategoryId[categoryId]
              }
            case 'byTournamentId':
              return (tournamentId) => {
                if (!byTournamentId[tournamentId]) {
                  byTournamentId[tournamentId] = []
                }
                return byTournamentId[tournamentId]
              }
            case 'liveSportIdsCounter':
              return liveSportIdsCounter
            case 'liveSportIdsWithVideoCounter':
              return liveSportIdsWithVideoCounter
            case 'liveSportCounterById':
              return (sportId) => liveSportIdsCounter.get(sportId)
            case 'liveCategoryCounterById':
              return (categoryId) => liveCategoryIdsCounter.get(categoryId)
            case 'liveTournamentCounterById':
              return (tournamentId) =>
                liveTournamentIdsCounter.get(tournamentId)
            case 'findByCompetitorName':
              /* eslint-disable */
              return (name) => {
                return live.concat(prematch).filter((event) => {
                  if (event && event.home && event.home.name) {
                    if (
                      ~event.home.name.toLowerCase().indexOf(name.toLowerCase())
                    ) {
                      return event
                    }
                  }
                  if (event && event.away && event.away.name) {
                    if (
                      ~event.away.name.toLowerCase().indexOf(name.toLowerCase())
                    ) {
                      return event
                    }
                  }
                })
              }
            /* eslint-enable */
            default:
              return arr[prop]
          }
        },
      },
    )
  },
})
export default IndexedActual
