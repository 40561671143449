import { ref, type Ref, unref } from 'vue'
import { defineStore } from 'pinia'
import type {
  MarketsConfig,
  MarketsFiltersConfig,
  FastBetConfig,
} from '../types'
import { isEmptyObject } from '@st/utils'

type FileNames =
  | 'publicFileMarketbook'
  | 'publicFileMarketFilterConfig'
  | 'publicFileFastBetConfig'

interface FetchConfigOptions<T> {
  configRef: Ref<T>
  fileName: FileNames
  settings?: Record<string, unknown>
}

export const useMarketsBookStore = defineStore('marketsBook', () => {
  const marketsConfig = ref<MarketsConfig>({
    marketGroups: {},
    marketTable: {},
    categoriesAsSport: {},
  })
  const marketsFiltersConfig = ref<MarketsFiltersConfig>({})
  const fastbetConfig = ref<FastBetConfig>({})

  async function fetchConfigFromUrl<T>({
    configRef,
    fileName,
    settings,
  }: FetchConfigOptions<T>) {
    try {
      const configUrl = unref(settings)?.[fileName] as string

      if (!configUrl) {
        handleConfigError(fileName)
        return
      }

      const result = await fetch(configUrl)
      configRef.value = await result.json()
    } catch (error) {
      handleConfigError(fileName, error)
    }
  }
  function handleConfigError(fileName: string, error?: unknown) {
    console.error(
      `Failed to load ${fileName} config from system settings link`,
      error,
    )
  }
  async function getMarketsbookConfig(settings?: Record<string, unknown>) {
    if (!isEmptyObject(marketsConfig.value.marketGroups)) return

    const fileName = 'publicFileMarketbook'
    fetchConfigFromUrl({
      configRef: marketsConfig,
      fileName,
      settings,
    })
  }
  async function getMarketsFilterConfig(settings?: Record<string, unknown>) {
    if (!isEmptyObject(marketsFiltersConfig.value)) return

    const fileName = 'publicFileMarketFilterConfig'

    fetchConfigFromUrl({
      configRef: marketsFiltersConfig,
      fileName,
      settings,
    })
  }
  async function getFastBetConfig(settings?: Record<string, unknown>) {
    const fileName = 'publicFileFastBetConfig'

    fetchConfigFromUrl({
      configRef: fastbetConfig,
      fileName,
      settings,
    })
  }

  function fetchMarketsConfigs(settings?: Record<string, unknown>) {
    getMarketsFilterConfig(settings)
    getMarketsbookConfig(settings)
    getFastBetConfig(settings)
  }

  return {
    marketsConfig,
    marketsFiltersConfig,
    fastbetConfig,
    getMarketsbookConfig,
    fetchMarketsConfigs,
  }
})
