const IndexedMenu = new Proxy(Array, {
  construct(Target, [args]) {
    let indexTypeId = {}
    const allTournaments = []

    function setInCache(data) {
      const sLen = data.length
      for (let i = 0; i < sLen; i++) {
        const sport = data[i]
        sport.priority = i
        indexTypeId[`sport:${sport.id}`] = sport
        const categories = sport.categories || []
        const cLen = categories.length

        for (let j = 0; j < cLen; j++) {
          const category = categories[j]
          if (!category.weight) {
            category.weight = 0
          }
          category.updateWeight = function (weight = 11) {
            this.weight = weight
          }
          indexTypeId[`category:${category.id}`] = category
          const tournaments = category.tournaments || []
          const tLen = tournaments.length

          for (let k = 0; k < tLen; k++) {
            const tournament = tournaments[k]
            tournament.priority = i + j + k
            // set reactive weight
            tournament.weight = 11
            tournament.updateWeight = function (weight = 11) {
              this.weight = weight
            }
            indexTypeId[`tournament:${tournament.id}`] = tournament
            allTournaments.push(tournament)
          }
        }
      }
    }

    return new Proxy(new Target(...args), {
      get(arr, prop) {
        switch (prop) {
          case 'push':
            return (...items) => {
              arr[prop].call(arr, ...items) // eslint-disable-line
              setInCache(items)
              const onMenuInited = arr.onMenuInited
              if (onMenuInited && typeof onMenuInited === 'function') {
                onMenuInited()
              }
            }
          case 'findById':
            return (type, id, props = []) => {
              const item = indexTypeId[`${type}:${id}`]
              if (item && props.length) {
                return props.reduce((memo, prop) => {
                  memo[prop] = item[prop]
                  return memo
                }, {})
              }
              return item
            }
          case 'tournaments':
            return allTournaments
          case 'flush':
            return () => {
              arr.splice(0, arr.length)
              indexTypeId = {}
              allTournaments.splice(0, allTournaments.length)
            }
          case 'removeTournament':
            return (tournamenId) => {
              if (indexTypeId[`tournament:${tournamenId}`]) {
                delete indexTypeId[`tournament:${tournamenId}`]
              }
              const index = allTournaments.findIndex(
                (t) => t.id === tournamenId,
              )
              if (~index) {
                const [tournament] = allTournaments.splice(index, 1)
                if (tournament && tournament.parents) {
                  const [sport, category] = tournament.parents
                  const _sportId = sport.id
                  const _categoryId = category.id
                  const sportIndex = arr.findIndex((s) => s.id === _sportId)
                  const _sport = arr[sportIndex]
                  if (_sport && _sport.categories) {
                    const _categories = _sport.categories || []
                    const _categoryIndex = _categories.findIndex(
                      (c) => c.id === _categoryId,
                    )
                    if (~_categoryIndex) {
                      const _category = _categories[_categoryIndex]
                      if (_category) {
                        const _tournaments = _category.tournaments || []
                        const currentIndex = _tournaments.findIndex(
                          (t) => t.id === tournamenId,
                        )
                        /**
                         * remove tournament && remove parents if them empty
                         */
                        if (~currentIndex) {
                          _tournaments.splice(currentIndex, 1)
                        }
                        if (!_tournaments.length) {
                          _categories.splice(_categoryIndex, 1)
                          if (!_categories.length) {
                            arr.splice(sportIndex, 1)
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          case 'addTournament':
            return (tournament, category, lang, weight = 11) => {
              /**
               * init objects
               */
              const tournamentName = tournament.name
              tournament.name =
                typeof tournamentName === 'object'
                  ? tournamentName[lang] || tournamentName.en
                  : tournamentName
              const categoryName = category.name
              category.name =
                typeof categoryName === 'object'
                  ? categoryName[lang] || categoryName.en
                  : categoryName
              if (!category.weight) {
                category.weight = weight
              }
              category.updateWeight = function (weight = 11) {
                this.weight = weight
              }
              tournament.weight = weight
              tournament.updateWeight = function (weight = 11) {
                this.weight = weight
              }

              const sportId = category.sport_id
              const categoryId = category.id
              const _sport = arr.find((s) => s.id === sportId)
              if (_sport) {
                if (!_sport.categories && _sport.categories.length) {
                  _sport.categories = []
                }
                const _categories = _sport.categories || []
                const _category = _categories.find((c) => c.id === categoryId)

                if (!_category) {
                  _categories.push({
                    ...category,
                    tournaments: [tournament],
                  })
                  indexTypeId[`category:${categoryId}`] = category
                  indexTypeId[`tournament:${tournament.id}`] = tournament
                } else {
                  if (
                    !(_category.tournaments && _category.tournaments.length)
                  ) {
                    _category.tournaments = []
                  }
                  const _tournaments = _category.tournaments
                  _tournaments.push(tournament)
                  indexTypeId[`tournament:${tournament.id}`] = tournament
                }
                allTournaments.push(tournament)
              }
            }
          case 'addSport':
            return (sport) => {
              const { id } = sport
              if (indexTypeId[`sport:${id}`]) return
              const copy = arr.slice()
              copy.push(sport)
              copy.sort((a, b) => {
                const [aW, bW] = [a.weight, b.weight]
                if (aW > bW) return 1
                if (aW < bW) return -1
                const [aN, bN] = [a.name, b.name]
                if (aN > bN) return 1
                if (aN < bN) return -1
                return 0
              })
              const _findPlace = copy.findIndex((sp) => sp.id === id)
              if (~_findPlace) {
                arr.splice(_findPlace, 0, sport)
              }
              setInCache([sport])
            }
          default:
            return arr[prop]
        }
      },
    })
  },
})

export default IndexedMenu
