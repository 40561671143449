import type { AccountTransactionNotification, Notification } from '../types'

export function useAccountTransactionNotification(
  notification: Ref<Notification>,
) {
  function isAccountTransactionNotificationGuard(
    notificationItem: Ref<Notification>,
  ): notificationItem is Ref<AccountTransactionNotification> {
    return 'operationType' in notificationItem.value.data
  }

  const notificationOperationType = computed(() => {
    if (!isAccountTransactionNotificationGuard(notification)) return null

    return notification.value.data.operationType
  })

  const useAccountIcon = computed(() => {
    if (notificationOperationType.value) {
      return {
        freebetAccrual: 'freebet' as const,
        freebetProcessAccrual: 'freebet' as const,
        referralAccrual: 'user-plus-solid' as const,
        freespinRewardAccrual: 'freebet' as const,
      }[notificationOperationType.value]
    }

    return ''
  })

  return {
    notificationOperationType,
    useAccountIcon,
  }
}
