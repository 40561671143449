import type {
  CustomMenuItem,
  Custom,
  SbSport,
  CustomMenuEntity,
  CustomMenuClass,
  SbCategory,
  SbTournament,
} from './types'
import { isVue2, reactive } from 'vue-demi'

const ENTITY_TYPE = {
  tournament: 'tournament',
  category: 'category',
  sport: 'sport',
  custom: 'custom',
} as const

export type {
  CustomMenuClass,
  CustomMenuItem,
  SbSport,
  SbCategory,
  SbTournament,
}

export default class CustomMenu implements CustomMenuClass {
  public menu: CustomMenuItem[]
  public filteredMenu: CustomMenuItem[]
  public fullMenu: CustomMenuItem[]
  public sportsbookTree: SbSport[]
  public customs: Custom[]
  entitiesHashMap: Map<string, CustomMenuItem>
  customTreeHashMap: Map<string, CustomMenuItem>
  lang: string
  adminMode: boolean

  constructor(lang: string, adminMode = false) {
    this.sportsbookTree = []
    this.customs = []
    this.menu = isVue2 ? [] : reactive([])
    this.filteredMenu = []
    this.fullMenu = []
    this.entitiesHashMap = isVue2 ? new Map() : reactive(new Map())
    this.customTreeHashMap = isVue2 ? new Map() : reactive(new Map())
    this.lang = lang
    this.adminMode = adminMode
  }

  init() {
    const entities = this.parseTree(this.customs, this.sportsbookTree)
    const menu = this.createCustomMenuTree(entities, false)
    this.menu.splice(0, this.menu.length, ...menu)
    if (this.adminMode) {
      this.fullMenu.splice(
        0,
        this.fullMenu.length,
        ...this.createCustomMenuTree(entities, this.adminMode),
      )
    }
    return menu
  }

  public setSportsbookTree(tree: SbSport[] = []) {
    this.sportsbookTree = tree
  }

  public setCustoms(customs: Custom[] = []) {
    this.customs = customs
    this.init()
  }

  public setFilteredMenu(menu: CustomMenuItem[] = []) {
    this.filteredMenu.length = 0
    menu.forEach((item) => {
      this.filteredMenu.push(Object.freeze(item))
    })
  }

  public findById(type: CustomMenuItem['type'], id: number | string) {
    const keyString = type + '-' + id
    return (
      this.customTreeHashMap.get(keyString) ||
      this.entitiesHashMap.get(keyString)
    )
  }

  sortByIndexAndName<T extends { sortIndex: number; name: string }>(
    array: T[],
  ) {
    array.sort((a, b) => {
      const resultSortIndex = a.sortIndex - b.sortIndex
      if (resultSortIndex) return resultSortIndex
      const aName = a.name
      const bName = b.name
      if (aName > bName) {
        return 1
      } else if (aName < bName) {
        return -1
      }
      return 0
    })
  }

  createCustomMenuTree(
    entities: CustomMenuEntity[],
    adminMode = false,
  ): Readonly<CustomMenuItem[]> {
    const entitiesHashMap = this.entitiesHashMap
    const customTreeHashMap = this.customTreeHashMap
    entitiesHashMap.clear()
    customTreeHashMap.clear()

    const byParentId = entities.reduce<Record<string, CustomMenuEntity[]>>(
      (memo, entity) => {
        entitiesHashMap.set(entity.entityId, entity as CustomMenuItem)
        const parentId = entity.parentId ?? 'null'
        if (!memo[parentId]) {
          memo[parentId] = []
        }
        memo[parentId].push(entity)
        return memo
      },
      {},
    )
    let rootLength = 0
    const roots = byParentId.null || []
    const menu = roots.reduce<CustomMenuItem[]>((rootMemo, root) => {
      const rootType = root.type
      const rootId = root.id
      let secondLevelIndex = 0
      if (rootType === ENTITY_TYPE.tournament) {
        rootLength = rootMemo.push(
          Object.assign({}, root, {
            level: 0,
            link: `/${ENTITY_TYPE.tournament}/${rootId}`,
            liveLink: `/live/${ENTITY_TYPE.tournament}/${rootId}`,
          }),
        )
      } else {
        const rootLink = `/${
          rootType === ENTITY_TYPE.category
            ? ENTITY_TYPE.category
            : ENTITY_TYPE.sport
        }/${rootId}`
        const rootLiveLink = `/${
          rootType === ENTITY_TYPE.category
            ? `live/${ENTITY_TYPE.category}`
            : 'live'
        }/${rootId}`
        const rootChildren = byParentId[root.entityId]
        if (rootChildren) {
          const filteredRootChildren = rootChildren.reduce<CustomMenuItem[]>(
            (secondLevelMemo, secondLevel) => {
              const secondLevelId = secondLevel.id
              if (secondLevel.type === ENTITY_TYPE.tournament) {
                secondLevelIndex = secondLevelMemo.push(
                  Object.assign({}, secondLevel, {
                    level: 1,
                    link: rootLink + '/' + secondLevelId,
                    liveLink: rootLiveLink + '/' + secondLevelId,
                  }),
                )
              } else {
                const secondLevelChildren = byParentId[secondLevel.entityId]
                const secondLevelLink = rootLink + '/' + secondLevelId
                const secondLevelLiveLink = rootLiveLink + '/' + secondLevelId
                if (secondLevelChildren) {
                  this.sortByIndexAndName(secondLevelChildren)
                  secondLevelIndex = secondLevelMemo.push(
                    Object.assign({}, secondLevel, {
                      children: secondLevelChildren.map((child) => {
                        const childId = child.id
                        const _child = Object.assign({}, child, {
                          level: 2,
                          link: secondLevelLink + '/' + childId,
                          liveLink: secondLevelLiveLink + '/' + childId,
                        })
                        customTreeHashMap.set(_child.entityId, _child)
                        return _child
                      }),
                      level: 1,
                      link: secondLevelLink,
                      liveLink: secondLevelLiveLink,
                    }),
                  )
                  customTreeHashMap.set(
                    secondLevel.entityId,
                    secondLevelMemo[secondLevelIndex - 1],
                  )
                } else if (adminMode) {
                  secondLevelIndex = secondLevelMemo.push(
                    Object.assign({}, secondLevel, {
                      level: 1,
                      children: [],
                      link: secondLevelLink,
                      liveLink: secondLevelLiveLink,
                    }),
                  )
                  customTreeHashMap.set(
                    secondLevel.entityId,
                    secondLevelMemo[secondLevelIndex - 1],
                  )
                }
              }
              return secondLevelMemo
            },
            [],
          )
          if (filteredRootChildren.length) {
            this.sortByIndexAndName(filteredRootChildren)
            rootLength = rootMemo.push(
              Object.assign({}, root, {
                level: 0,
                children: filteredRootChildren,
                link: rootLink,
                liveLink: rootLiveLink,
              }),
            )
          } else if (adminMode) {
            rootLength = rootMemo.push(
              Object.assign({}, root, {
                level: 0,
                children: [],
                link: rootLink,
                liveLink: rootLiveLink,
              }),
            )
          }
        } else if (adminMode) {
          rootLength = rootMemo.push(
            Object.assign({}, root, {
              level: 0,
              children: [],
              link: rootLink,
              liveLink: rootLiveLink,
            }),
          )
        }
      }
      customTreeHashMap.set(root.entityId, rootMemo[rootLength - 1])
      return rootMemo
    }, [])

    this.sortByIndexAndName(menu)
    this.customTreeHashMap = customTreeHashMap
    this.entitiesHashMap = entitiesHashMap
    return Object.freeze(menu)
  }

  parseTree(customs: Custom[], sportsbookTree: SbSport[]): CustomMenuEntity[] {
    const entities: CustomMenuEntity[] = []
    const customsHashMapById: Record<number, Custom> = {}
    const customsHashMapByTypeAndSbId: {
      sport: Record<number, Custom>
      category: Record<number, Custom>
      tournament: Record<number, Custom>
    } = {
      sport: {},
      category: {},
      tournament: {},
    }
    const minMeanWeightByCategory: Record<number, number> = {}

    const getParentId = (
      { parentId, sbParentId, sbParentType }: Custom,
      fallback: string | null = null,
    ) => {
      if (parentId) {
        return `${ENTITY_TYPE.custom}-${parentId}`
      } else if (sbParentId) {
        return `${sbParentType}-${sbParentId}`
      } else if (parentId === null) {
        return null
      }
      return fallback
    }

    const setEntityName = (
      entity: CustomMenuEntity,
      name: Record<string, string> | string,
      lang: string,
      fallbackLang = 'en',
    ) => {
      if (typeof name === 'object') {
        entity.name = name[lang] || name[fallbackLang] || name.en
      } else {
        entity.name = name
      }
      return entity
    }
    /**
     * init hashmaps customs
     */
    customs.forEach((custom) => {
      const {
        id,
        sbId,
        sbType,
        parentId,
        sbParentId,
        sbParentType,
        name,
        weight,
      } = custom
      customsHashMapById[id] = custom
      if (sbId && sbType) {
        customsHashMapByTypeAndSbId[sbType][sbId] = custom
      } else {
        let _parentId = null
        if (parentId) {
          _parentId = `${ENTITY_TYPE.custom}-${parentId}`
        } else if (sbParentType && sbParentId) {
          _parentId = `${sbParentType}-${sbParentId}`
        }
        const entityId = `${ENTITY_TYPE.custom}-${id}`
        const entity: CustomMenuEntity = {
          type: ENTITY_TYPE.custom,
          /**
           *  TODO Тут реальная ошибка типизации. По идее id должен быть number, а тут string
           *  если расширить тип - будет миллион ошибок TS
           *  если изменить код могут быть сайд эффекты с которыми сейчас не хочется разбираться
           */
          // @ts-expect-error see comment above
          id: entityId,
          entityId,
          parentId: _parentId,
          sortIndex: weight,
          name: '',
        }
        setEntityName(entity, name, this.lang)
        entities.push(entity)
      }
    })

    sportsbookTree.forEach((sport) => {
      const { categories = [], ..._sport } = sport

      const sportEntity: CustomMenuEntity = {
        ..._sport,
        entityId: `${ENTITY_TYPE.sport}-${_sport.id}`,
        type: ENTITY_TYPE.sport,
        parentId: null,
        sortIndex: _sport.weight,
      }

      setEntityName(sportEntity, _sport.name, this.lang)

      const _sportCustom = customsHashMapByTypeAndSbId.sport[_sport.id]
      if (_sportCustom && _sportCustom.weight) {
        sportEntity.sortIndex = _sportCustom.weight
      }
      entities.push(sportEntity)

      categories.forEach((category) => {
        const { tournaments = [], ..._category } = category
        const categoryId = _category.id

        const categoryEntity: CustomMenuEntity = {
          ..._category,
          entityId: `${ENTITY_TYPE.category}-${categoryId}`,
          type: ENTITY_TYPE.category,
          parentId: sportEntity.entityId,
          sortIndex: 0,
          sportId: sportEntity.id,
          countryCode: _category.country_code,
        }

        setEntityName(categoryEntity, _category.name, this.lang)

        const _categoryCustom = customsHashMapByTypeAndSbId.category[categoryId]
        if (_categoryCustom) {
          categoryEntity.parentId = getParentId(
            _categoryCustom,
            categoryEntity.parentId,
          )
          if (_categoryCustom.weight) {
            categoryEntity.sortIndex = _categoryCustom.weight
          }
        }

        tournaments.forEach((tournament) => {
          const tournamentEntity: CustomMenuEntity = {
            ...tournament,
            entityId: `${ENTITY_TYPE.tournament}-${tournament.id}`,
            type: ENTITY_TYPE.tournament,
            parentId: categoryEntity.entityId,
            sortIndex: tournament.meanWeight,
            sportId: sportEntity.id,
            categoryId: categoryEntity.id,
            countryCode: categoryEntity.countryCode,
          }
          setEntityName(tournamentEntity, tournamentEntity.name, this.lang)

          const _tournamentCustom =
            customsHashMapByTypeAndSbId.tournament[tournamentEntity.id]
          if (_tournamentCustom) {
            tournamentEntity.parentId = getParentId(
              _tournamentCustom,
              categoryEntity.entityId,
            )
            tournamentEntity.sortIndex = _tournamentCustom.weight || 0
          }
          const minWeight = minMeanWeightByCategory[categoryId]
          if (
            tournamentEntity.parentId === categoryEntity.entityId &&
            (!minWeight || minWeight > tournamentEntity.sortIndex)
          ) {
            minMeanWeightByCategory[categoryId] = tournamentEntity.sortIndex
          }
          entities.push(tournamentEntity)
        })
        if (!categoryEntity.sortIndex) {
          categoryEntity.sortIndex = minMeanWeightByCategory[categoryId]
        }
        entities.push(categoryEntity)
      })
    })
    return entities
  }

  public getTournamentsIdsFromEntity(
    entity: CustomMenuItem,
    tournaments: number[] = [],
  ): number[] {
    if (entity.children) {
      entity.children.forEach((child) => {
        if (!child.children) {
          tournaments.push(child.id)
        } else {
          this.getTournamentsIdsFromEntity(child, tournaments)
        }
      })
    } else {
      tournaments.push(entity.id)
    }
    return tournaments
  }
}
