import type { BinaryDecodedActual } from '../types'
import { groupBy } from '@st/utils'

export function buildMenuFromDecodedActual({
  sports,
  categories,
  tournaments,
}: BinaryDecodedActual) {
  const extendTournaments = tournaments.map((tournament) => ({
    ...tournament,
    id: tournament.sbId,
    category_id: tournament.sbCategoryId,
    meanWeight: tournament.weight,
    name: tournament.name ?? '',
  }))
  const tournamentsByCategory = groupBy(
    extendTournaments,
    (tournament) => tournament.category_id,
  )
  const extendedCategories = categories.map((category) => ({
    ...category,
    id: category.sbId,
    sport_id: category.sbSportId,
    sportId: category.sbSportId,
    country_code: category.countryCode,
    tournaments: tournamentsByCategory.get(category.sbId),
  }))

  const categoriesBySport = groupBy(
    extendedCategories,
    (category) => category.sport_id,
  )

  return sports.map((sport) => ({
    ...sport,
    id: sport.sbId,
    categories: categoriesBySport.get(sport.sbId),
  }))
}
