import type { components } from '@st/api/paths'

export type SystemSettings = components['schemas']['SiteSystemSettings']
export type SystemCurrencySettings =
  components['schemas']['SiteCurrencySettings']

export interface SystemSettingsSocketPayload {
  name: keyof SystemSettings
  value: SystemSettings[keyof SystemSettings]
}

export interface SystemSettingsSocketCurrencyPayload {
  name: keyof SystemCurrencySettings
  value: SystemCurrencySettings[keyof SystemCurrencySettings]
  currencyId: string
}
export interface SystemSettingsSocket {
  settings: (
    | SystemSettingsSocketPayload
    | SystemSettingsSocketCurrencyPayload
  )[]
}

function isCurrencySetting(
  setting: SystemSettingsSocketPayload | SystemSettingsSocketCurrencyPayload,
): setting is SystemSettingsSocketCurrencyPayload {
  return (
    (setting as SystemSettingsSocketCurrencyPayload).currencyId !== undefined
  )
}

export const useSettingsStore = defineStore('settings', () => {
  const io = useSocket()
  const isReady = ref<boolean>(false)
  const settings = ref<SystemSettings | null>(null)

  async function fetchSettings() {
    try {
      const { data } = await useStFetch('/system-settings')
      settings.value = data.value
      isReady.value = true
    } catch (error) {
      console.error('Failed to load system settings', error)
    }
  }

  function setSettings(data: SystemSettings) {
    settings.value = data
    isReady.value = true
  }

  function updateSetting<K extends keyof SystemSettings>({
    name,
    value,
  }: {
    name: K
    value: SystemSettings[K]
  }) {
    if (settings.value) {
      settings.value[name] = value
    }
  }

  function updateCurrencySetting<K extends keyof SystemCurrencySettings>({
    value,
    name,
    currencyId,
  }: {
    name: K
    value: SystemCurrencySettings[K]
    currencyId: string
  }) {
    if (settings.value) {
      settings.value.currencySettings[currencyId][name] = value
    }
  }

  function systemSettingsListener({
    settings: socketSettings,
  }: SystemSettingsSocket) {
    socketSettings.forEach((item) => {
      if (isCurrencySetting(item)) {
        updateCurrencySetting(item)
      } else updateSetting(item)
    })
  }
  io.on('systemSettings', systemSettingsListener)

  return {
    settings,
    isReady,
    fetchSettings,
    setSettings,
  }
})
