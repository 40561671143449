import { delay } from '@st/utils'
import type { ActualLoader, MarketStoreInstance } from '../types'

const SHORT_ACTUAL_SCHEDULED_IN_MS = 24 * 60 * 60 * 1000
const LONG_ACTUAL_CHUNK_SIZE = 500

export class SbLoader implements ActualLoader {
  #marketStore: MarketStoreInstance
  public name = 'SbLoader'

  constructor(marketsStore: MarketStoreInstance) {
    this.#marketStore = marketsStore
  }

  async getActual() {
    await this.#loadShortActual()
    await this.#loadLongActual()
    this.#marketStore.systemStates.fullActualLoaded = true
  }

  async #loadShortActual() {
    const shortActual = await this.#getActualFromSb('short')
    await this.#marketStore.init(shortActual)
  }

  async #loadLongActual() {
    const longActual = await this.#getActualFromSb('long')
    for (let i = 0; i < longActual.length; i += LONG_ACTUAL_CHUNK_SIZE) {
      const chunk = longActual.slice(i, i + LONG_ACTUAL_CHUNK_SIZE)
      this.#marketStore.initActual(chunk)
      await delay(10)
    }
  }

  async #getActualFromSb(type: 'short' | 'long') {
    const scheduledSearchType = type === 'short' ? '$lte' : '$gte'
    const scheduledNanoseconds =
      new Date().getTime() + SHORT_ACTUAL_SCHEDULED_IN_MS
    const query: any = {
      lang: this.#marketStore.lang,
      fallbackLang: this.#marketStore.getFallbackLangForLang(
        this.#marketStore.lang,
      ),
      withProps: ['competitors', 'match.venue', 'markets', 't_settings'],
      query: {
        scheduled: {
          [scheduledSearchType]: scheduledNanoseconds,
        },
      },
    }
    if (this.#marketStore?.actualMarketsIds?.length) {
      query.withQuery = {
        markets: { id: { $in: this.#marketStore.actualMarketsIds } },
      }
    }

    return this.#marketStore.client.findActualSportEvents(query)
  }
}
