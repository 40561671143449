import { useLocalStorage } from '@st/use/composables'

export default defineNuxtPlugin({
  name: 'referralCode',
  setup(nuxt) {
    const referralCodeStorage = useLocalStorage<string>('referralCode', '')

    nuxt.hook('app:mounted', async () => {
      const route = useRoute()
      const code = route.query.ref

      if (code) {
        referralCodeStorage.value = String(code)
      }
    })
  },
})
