import { useUserStore } from '../stores/useUserStore'

export default defineNuxtPlugin({
  name: 'userLogout',
  order: 2,
  setup(nuxt) {
    const router = useRouter()
    const { resetUserData } = useUserStore()
    const { user } = storeToRefs(useUserStore())
    const bus = useApiEventBus()

    nuxt.hook('app:mounted', async () => {
      bus.on('responseError', (context) => {
        const NON_AUTH_ERROR = 'USER_UNAUTHORIZED'
        /* внутри context пустой error поэтому приходится доставать из _data */
        const error = context.response?._data?.error // eslint-disable-line

        if (NON_AUTH_ERROR !== error) return

        if (user.value) {
          resetUserData()
          router.replace({ query: {}, path: '/' })
        }
      })
    })
  },
})
