import { useUserStore } from '@st/user/stores/useUserStore'
import type {
  UserVerification,
  VerificationActiveLevelState,
  UserVerificationTypeCreate,
} from '../components/types'

export const useVerificationStore = defineStore('verification', () => {
  const stFetch = useRawStFetch()
  const { $i18n } = useNuxtApp()
  const { t } = $i18n
  const { user } = storeToRefs(useUserStore())
  const userVerification = ref<UserVerification | null>(null)
  const currentLevel = computed(() => user.value?.kycLevel ?? 0)

  async function fetchUserVerification() {
    try {
      const verificationAnswer: UserVerification = await stFetch(
        '/user-verification/active/get',
        {
          method: 'post',
        },
      )
      userVerification.value =
        verificationAnswer.status === 'approved' ||
        verificationAnswer.status === 'canceled'
          ? null
          : verificationAnswer
    } catch (e: unknown | Error) {
      console.error(e)
    }
  }

  async function createUserVerification(type: UserVerificationTypeCreate) {
    try {
      await stFetch('/user-verification/create', {
        method: 'post',
        body: { type },
      })
      await fetchUserVerification()
    } catch (e) {
      console.error(e)
    }
  }

  const activeLevel = computed(() => {
    if (
      !userVerification.value ||
      userVerification.value.type === 'actionLiveness'
    ) {
      return currentLevel.value + 1
    }
    if (userVerification.value.type === 'level1') {
      return 1
    }
    if (userVerification.value.type === 'level2') {
      return 2
    }

    return 3
  })

  const activeLevelState = computed<VerificationActiveLevelState>(() => {
    if (!userVerification.value) {
      return {
        state: 'active',
      }
    }

    switch (userVerification.value.status) {
      case 'adminWait':
      case 'adminInProgress': {
        return {
          state: 'pending',
        }
      }
      case 'userWait': {
        return {
          state: 'yellow',
          message: userVerification.value.rejectLabels
            ? t('verification.pressButtonAgain')
            : '',
        }
      }
      case 'rejected': {
        return {
          state: 'red',
          message: t('verification.contactSupport'),
        }
      }
      default: {
        return {
          state: 'active',
        }
      }
    }
  })
  return {
    userVerification,
    activeLevel,
    activeLevelState,
    currentLevel,
    fetchUserVerification,
    createUserVerification,
  }
})
