<template>
  <article
    class="notification"
    data-t="notification-item"
    :class="extendedClasses"
    @click="handleNotificationClick"
  >
    <div class="wrapper">
      <div class="logo">
        <div class="icon-container">
          <StIcon :size="20" :name="notificationIcon" />
          <div v-if="currencyIcon" class="currency-icon" data-t="currency-icon">
            <StIcon :name="currencyIcon" :size="16" />
          </div>
        </div>
      </div>
      <div class="info">
        <header class="header">
          <h4 class="title">
            <span data-t="subject">{{ subjectMessage }}</span>
            <StIcon
              v-if="!props.item.read && !props.compact"
              class="live"
              :size="16"
              name="live"
            />
          </h4>
          <span v-if="!props.compact" class="time">{{ time }}</span>
        </header>
        <div class="body">
          <component
            :is="bodyMessageComponent"
            v-if="props.item.type !== 'admin'"
            :notification="props.item"
            data-t="body-message"
          />
          <p v-if="props.item.type === 'admin'" class="text">
            {{ props.item.data.text }}
          </p>
        </div>
      </div>
    </div>
    <div v-if="props.compact" class="close" @click="handleCloseNotification">
      <StIcon :size="16" name="cross-large" />
    </div>
  </article>
</template>

<script setup lang="ts">
import { useNotification } from '../../../composables/useNotification'
import { useCurrencyNotification } from '../../../composables/useCurrencyNotification'
import { useAccountTransactionNotification } from '../../../composables/useAccountTransactionNotification'
import type { Notification } from '../../../types'

interface Props {
  item: Notification
  compact?: boolean
  isMobile?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  compact: false,
})

const { item: notification } = toRefs(props)

const {
  classes,
  time,
  notificationIcon,
  subjectMessage,
  notificationLink,
  readNotification,
} = useNotification(notification)
const { currencyIcon } = useCurrencyNotification(notification)
const { notificationOperationType } =
  useAccountTransactionNotification(notification)

const extendedClasses = computed(() => [
  ...classes.value,
  { compact: props.compact },
  { mobile: props.isMobile },
])

async function handleNotificationRead() {
  if (props.compact) return

  await readNotification()
  notification.value.read = true
}

const router = useRouter()
async function handleNotificationClick() {
  await handleNotificationRead()

  if (notificationLink.value) {
    router.push(notificationLink.value)
  }
}

async function handleCloseNotification() {
  await readNotification()
  notification.value.read = true
}

const bodyMessageComponent = computed(() => {
  /* 
    у accountTransactionCreated компонент сопоставляется
    по полю operationType внутри data
  */
  if (notificationOperationType.value) {
    switch (notificationOperationType.value) {
      case 'referralAccrual':
        return defineAsyncComponent(
          () => import('./templates/ReferalAccrualMessage.vue'),
        )
      case 'freebetAccrual':
      case 'freebetProcessAccrual':
        return defineAsyncComponent(
          () => import('./templates/FreebetMessage.vue'),
        )
      case 'freespinRewardAccrual':
        return defineAsyncComponent(
          () => import('./templates/FreespinWinMessage.vue'),
        )
      default:
        return null
    }
  }

  /* 
    компонент подбирается по полю type нотификации
  */
  switch (notification.value.type) {
    case 'depositCreated':
      return defineAsyncComponent(
        () => import('./templates/DepositCreatedMessage.vue'),
      )
    case 'depositProcessed':
      return defineAsyncComponent(
        () => import('./templates/DepositProcessedMessage.vue'),
      )
    case 'withdrawalProcessed':
      return defineAsyncComponent(
        () => import('./templates/WithdrawalProcessedMessage.vue'),
      )
    case 'betProcessedLossOrdinary':
    case 'betProcessedWinOrdinary':
      return defineAsyncComponent(
        () => import('./templates/BetProcessedOrdinaryMessage.vue'),
      )
    case 'betUnacceptedOrdinary':
      return defineAsyncComponent(
        () => import('./templates/BetUnacceptedOrdinaryMesage.vue'),
      )
    case 'betProcessedWinExpress':
    case 'betProcessedLossExpress':
      return defineAsyncComponent(
        () => import('./templates/BetProcessedExpressMessage.vue'),
      )
    case 'betUnacceptedExpress':
      return defineAsyncComponent(
        () => import('./templates/BetUnacceptedExpressMesage.vue'),
      )
    case 'userVerificationCreated':
      return defineAsyncComponent(
        () => import('./templates/VerificationMessage.vue'),
      )
    case 'bonusForDepositProgramUsersCreated':
      return defineAsyncComponent(
        () => import('./templates/BonusForDepositProgramCreated.vue'),
      )
    case 'userVerificationStatus':
      return defineAsyncComponent(
        () => import('./templates/VerificationStatus.vue'),
      )
    case 'bonusCreated':
    case 'bonusExpired':
      return defineAsyncComponent(() => import('./templates/BonusMessage.vue'))
    case 'userFreespinProgramCreated':
      return defineAsyncComponent(
        () => import('./templates/FreespinAccrualMessage.vue'),
      )
    default:
      return null
  }
})
</script>

<style scoped>
.icon-container {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 36px;
  height: 36px;

  background-color: rgb(255 255 255 / 16%);
  border-radius: var(--border-radius-full);
}

.currency-icon {
  position: absolute;
  right: -4px;
  bottom: -4px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--background-tertiary);
  border: 2px solid var(--background-secondary);
  border-radius: var(--border-radius-full);
}

.text {
  margin: 0;
  font: var(--desktop-text-xs-medium);
  color: var(--text-secondary);
}

.notification {
  cursor: pointer;

  position: relative;

  overflow: hidden;
  display: flex;
  flex: 1 0 0;
  gap: var(--spacing-150);
  align-items: flex-start;

  min-width: 0;
  padding: var(--spacing-125) var(--spacing-150);

  background-color: var(--background-secondary);
  border-radius: var(--border-radius-100);

  &:hover {
    background-color: var(--background-tertiary);
  }

  &.compact {
    cursor: default;
    padding-right: var(--spacing-500);

    &::after {
      content: '';

      position: absolute;
      z-index: 1;
      right: var(--spacing-400);
      bottom: 0;
      left: 0;

      display: block;

      height: 3px;

      background-color: var(--background-tertiary);
    }

    &::before {
      content: '';

      position: absolute;
      z-index: 2;
      right: var(--spacing-400);
      bottom: 0;
      left: 0;

      display: block;

      height: 3px;

      background-color: var(--button-ghost-default);

      animation: progress 3.5s linear forwards;
    }
  }

  &.mobile {
    background: var(--background-primary);

    .icon-container {
      background: rgba(255 255 255 / 0.12%);
    }

    .currency-icon {
      border: 2px solid var(--background-primary);
    }

    .text {
      color: var(--text-tertiary);
    }
  }

  &.active:not(.compact) {
    background: rgb(255 106 20 / 12%);

    .currency-icon {
      border-color: rgb(255 106 20 / 12%);
    }

    &:hover {
      background-color: rgb(255 106 20 / 20%);

      .currency-icon {
        border-color: rgb(255 106 20 / 20%);
      }
    }
  }

  &.support {
    .icon-container {
      /* stylelint-disable */
      background: conic-gradient(
          from 158deg at 50% 50%,
          #ff2214 48.6deg,
          #ff5b14 186.8376deg,
          #ff8514 299.2193deg
        ),
        #fe5e01;

      /* stylelint-enable */
      box-shadow: 0 5.333px 21.333px 0 rgb(255 50 21 / 20%);
    }
  }
}

.header {
  display: flex;
  gap: var(--spacing-050);
  align-items: flex-end;
  margin-bottom: var(--spacing-050);
}

.title {
  display: inline-block;

  margin: 0;

  color: var(--text-primary);
  white-space: nowrap;
  line-height: 1; /* stylelint-disable-line */

  span {
    font: var(--desktop-text-sm-semibold);
    white-space: normal;
    vertical-align: middle;
  }

  .live {
    margin-left: var(--spacing-050);
    color: #fe5e01; /* stylelint-disable-line */
    vertical-align: middle;
  }
}

.logo {
  display: flex;
  align-items: center;
  margin-top: var(--spacing-025);
}

.wrapper {
  display: flex;
  flex-grow: 1;
  gap: var(--spacing-150);
  align-items: flex-start;
}

.time {
  overflow: hidden;

  min-width: 88px;
  margin-left: auto;

  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.info {
  flex-grow: 1;
}

.action-button {
  margin-top: var(--spacing-150);
}

.close {
  cursor: pointer;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 32px;

  color: var(--text-tertiary);

  border-left: 1px solid rgb(255 255 255 / 4%);
}

@keyframes progress {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}
</style>
