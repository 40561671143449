/* eslint-disable */
import { SportsbookClientLite as SportsbookClient } from '@sb/client-lite'
import {
  IndexedActual,
  IndexedArray,
  Market,
  Sport,
  SportEvent,
} from './classes/index'
import IndexedMenu from './classes/IndexedMenu'
import CustomMenu from './classes/CustomMenu'
import { findAll } from './utils'
import { delay } from '@st/utils'
import { isVue2 } from 'vue-demi'
import { reactive, toRef } from 'vue'

const SPORT_EVENT = {
  STATUS: {
    LIVE: 1,
    PREMATCH: 0,
  },
  STATE: {
    DISABLED: 0,
  },
}

class MarketsStore {
  constructor(
    {
      sportbookApi,
      sportbookKey,
      sportbookUser,
      lang = 'ru',
      fallbackLang = 'en',
      actualMarketsIds,
      removeTimeout = 0,
      timeoutAfterSleep = 60,
      io,
      disableSelfDownload = false,
    },
    easyMode = false,
    adminMode = false,
  ) {
    this.easyMode = easyMode
    /*
      this.reactiveProps - missing fields in binary actual
      but we need them reactive, by setting default values
      for feature updates
    */
    this.reactiveProps = {
      sportEvent: {
        video: '',
        dicePlatformId: null,
      },
    }

    this.lang = lang
    this.fallbackLang = fallbackLang
    this.sportEvents = new IndexedActual([])
    this.indexedArray = new IndexedArray([])
    this.markets = isVue2 ? new Map() : reactive({})
    this.messages = []
    this.menu = new IndexedMenu([])
    this.actualReady = false
    this.sbCacheInited = false
    this.oddsChangeVisualHandler = null
    this.removeTimeout = removeTimeout * 1000 || 5
    this.actualMarketsIds = actualMarketsIds
    this.timeoutAfterSleep = timeoutAfterSleep
    this.socketsQueue = true
    this.timer = null
    this.timerStopped = null
    this.customMenu = new CustomMenu(lang, adminMode)
    this.allowedToStopMarketsStatuses = new Set([-1, 1, 0])
    this.disableSelfDownload = disableSelfDownload
    this.systemStates = reactive({
      fullActualLoaded: false,
      shortActualLoaded: false,
      sbCacheInited: false,
    })
    /**
     * init websocket
     */
    this.socket = io

    this.socket.on('connect', () => {
      console.log('Sockets connected!')
    })

    this.subscribeToSbEvent('sb_fixture_change', this.sb_fixture_change)
    this.subscribeToSbEvent('sb_bet_stop', this.sb_bet_stop)
    this.subscribeToSbEvent('sb_odds_change', this.sb_odds_change)

    /**
     * init sportsbook liba
     */
    this.client = new SportsbookClient(sportbookApi, sportbookKey, {
      user: sportbookUser,
    })

    if (!this.disableSelfDownload) {
      this.initWithSelfDownload()
    }
  }

  subscribeToSbEvent(event, handler) {
    this.socket.on(event, (msg) => {
      if (this.socketsQueue) {
        this.messages.push({ type: event, msg })
      } else {
        try {
          handler.call(this, this.parseSbEventMessage(msg))
        } catch (error) {
          console.error(error)
        }
      }
    })
  }

  parseSbEventMessage(message) {
    return JSON.parse(message)
  }

  async initCache() {
    await this.client.init({
      lang: this.lang,
      fallbackLang: this.getFallbackLangForLang(this.lang),
    })
    this.sbCacheInited = true
    this.systemStates.sbCacheInited = true
    this.callHandler('onSbCacheInited')
    return true
  }

  initWithSelfDownload() {
    Promise.all([
      this.initMenu(),
      this.getActual({ actualMarketsIds: this.actualMarketsIds }),
    ])
      .then(([menu, actual]) => {
        this.menu.push(...menu)
        console.info('MarketStore:menu:inited!')
        this.initActual(actual)
        this.actualReady = true
        this.callHandler('onActualReady')
        setTimeout(() => {
          this.readMessages()
          this.initCache()
        }, 100)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  async init(
    sportEvents = [],
    { menu = undefined, withCacheAndMessages = true } = {},
  ) {
    performance.mark('MS init: menu')
    if (menu) {
      this.customMenu.setSportsbookTree(JSON.parse(JSON.stringify(menu)))
      this.customMenu.init()
    } else {
      menu = await this.initMenu()
    }
    this.menu.push(...menu)
    performance.mark('MS init: actual')
    this.initActual(sportEvents)
    this.actualReady = true
    this.systemStates.shortActualLoaded = true
    this.callHandler('onActualReady')

    if (withCacheAndMessages) {
      performance.mark('MS init: cache and messages (async task)')
      await delay(100)
      this.readMessages()
      await this.initCache()
    }

    performance.mark('MS init: ended')
    performance.measure('MS init', 'MS init: menu', 'MS init: ended')
  }

  getActual() {
    console.time('MarketStore:actual:got')
    const actualMarketsIds = this.actualMarketsIds
    return new Promise((resolve, reject) => {
      const query = {
        lang: this.lang,
        fallbackLang: this.getFallbackLangForLang(this.lang),
        withProps: ['competitors', 'match.venue', 'markets', 't_settings'],
      }
      if (actualMarketsIds && actualMarketsIds.length) {
        query.withQuery = { markets: { id: { $in: actualMarketsIds } } }
      }
      this.client
        .findActualSportEvents(query)
        .then((resp) => {
          console.timeEnd('MarketStore:actual:got')
          resolve(resp)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }
  /* 
   actual - массив с евентами
   sports - спорты для меню (необязательный параметр)
   force - полная перезапись маркетов новыми (необязательный параметр)
  */
  initActual(actual = [], sports, force = true) {
    const menu = sports || this.menu,
      sportsCache = {},
      tournamentsWeights = {}
    const formattedActual = []
    actual.forEach((event) => {
      const markets = (event.markets || []).splice(0)
      if (event.markets) {
        delete event.markets
      }

      const reactivePropsInSportEvent = this.reactiveProps.sportEvent
      for (const key in reactivePropsInSportEvent) {
        const defaultValue = reactivePropsInSportEvent[key]
        if (!event[key]) event[key] = defaultValue
      }

      const eventId = event.id,
        sportId = event.sport_id,
        tournamentId = event.tournament_id

      let sport = sportsCache[sportId]

      if (!sport) {
        sport = menu.find((sp) => sp.id === sportId)
        sportsCache[sportId] = sport
      }

      if (sport) {
        const oldEvent = this.sportEvents.getById(event.id)
        if (oldEvent) {
          oldEvent.update(event)
          this.getMarketsByEventId(eventId, markets, event, force)
        } else {
          const sportEvent = new SportEvent(event, sport)
          formattedActual.push(sportEvent)
          this.getMarketsByEventId(eventId, markets, event)
        }
      }
      if (!tournamentsWeights[tournamentId]) {
        tournamentsWeights[tournamentId] = []
      }
      tournamentsWeights[tournamentId].push(event.weight)
    })

    this.sportEvents.push(...formattedActual)

    this.callHandler('onActualLengthChange', this.activeEventsLength)

    if (!this.easyMode) {
      const categoriesWeights = {}
      for (let tournamentId in tournamentsWeights) {
        const weights = tournamentsWeights[tournamentId]
        const tournament = menu.findById('tournament', tournamentId)
        if (tournament) {
          let tournamentWeight = weights.reduce((memo, eWeight) => {
            return (memo += eWeight)
          }, 1)
          if (weights.length) {
            tournamentWeight = Number(
              (tournamentWeight / weights.length).toFixed(2),
            )
          }
          tournament.updateWeight(tournamentWeight)
          const categoryId = tournament.category_id
          if (
            !categoriesWeights[categoryId] ||
            categoriesWeights[categoryId] > tournamentWeight
          ) {
            categoriesWeights[categoryId] = tournamentWeight
          }
        }
      }
      for (let category_id in categoriesWeights) {
        const category = menu.findById('category', category_id),
          weight = categoriesWeights[category_id]
        if (category && !category.weight) {
          category.updateWeight(weight)
        }
      }
    }
  }

  updateActual(updates = []) {
    const sportEvents = this.sportEvents,
      initActualLength = this.activeEventsLength,
      newEvents = [],
      actualIds = {}
    updates.forEach((event) => {
      const id = event.id
      const oldEvent = sportEvents.getById(id)
      if (oldEvent) {
        oldEvent.update(event)
      } else {
        newEvents.push(event)
      }
      actualIds[id] = true
    })

    for (let i = sportEvents.length - 1; i > -1; i--) {
      const event = sportEvents[i],
        eventId = event && event.id
      if (!actualIds[eventId]) {
        sportEvents.removeById(eventId)
        this.resetMarketsByEventId(eventId)
      }
    }

    if (newEvents.length) {
      this.initActual(newEvents)
    }
    const lastActualLength = this.activeEventsLength
    if (lastActualLength !== initActualLength) {
      this.callHandler('onActualLengthChange', lastActualLength)
    }
  }

  updateData() {
    return new Promise((resolve) => {
      this.getActual().then((resp) => {
        this.updateActual(resp)
        resolve()
      })
    })
  }

  readMessages() {
    clearTimeout(this.timer)
    const messages = this.messages.splice(0, 20)
    if (messages.length) {
      messages.sort((a, b) => {
        return a.timestamp - b.timestamp
      })

      messages.forEach(({ msg, type }) => {
        const handler = this[type]
        if (handler && typeof handler === 'function') {
          try {
            this[type](this.parseSbEventMessage(msg))
          } catch (error) {
            console.error(error)
          }
        }
      })
    }
    if (!this.socketsQueue) return
    this.timer = setTimeout(() => {
      this.readMessages()
    }, 500)
  }

  stopTimer() {
    this.socketsQueue = false
    this.timerStopped = new Date().getTime()
    clearTimeout(this.timer)
    this.readMessages()
  }

  startTimer(eventId = 0) {
    return new Promise((resolve) => {
      const { timerStopped, timeoutAfterSleep, timer } = this
      this.socketsQueue = true
      clearTimeout(timer)
      this.readMessages()
      const started = new Date().getTime()

      if (started - timerStopped >= timeoutAfterSleep * 1000) {
        this.updateData().then(() => {
          resolve()
        })
      }
      if (eventId) {
        this.downloadAllMarkets(eventId)
      }
    })
  }

  callHandler(handlerName, value) {
    const handler = this[handlerName]
    if (handler && typeof handler === 'function') {
      if (value) {
        handler(value)
      } else {
        handler()
      }
    }
  }

  /**
   * sb_odds_change handler
   * @param {*} newData
   * @returns
   */

  sb_odds_change(newData) {
    const id = newData.id
    if (!id) return
    const oldEvent = this.sportEvents.getById(id),
      oddsChangeVisualHandler = this.oddsChangeVisualHandler
    if (oldEvent) {
      const oldStatus = oldEvent.status,
        newStatus = newData.status
      oldEvent.update(newData)
      if (oldStatus === 0 && newStatus === 1) {
        this.sportEvents.moveToLive(id)
      } else if (oldStatus === 1 && newStatus > 1) {
        setTimeout(() => {
          this.removeEvent(id)
        }, this.removeTimeout)
      }
    }
    // else if (this.actualReady) {
    //   /*  TODO почему было убрано для добавления события
    //       которого нет в актуале
    //   */
    //   this.addSportEventToActual(newData)
    // }

    if (oldEvent && newData.markets && newData.markets.length) {
      const newDataTimestamp = newData.timestamp
      if (this.sbCacheInited) {
        this.client
          .resolveMarkets(
            { ...newData, competitors: oldEvent.competitors },
            this.lang,
            this.getFallbackLangForLang(this.lang),
          )
          .then((resolvedOdds) => {
            const oldMarkets = this.getMarketsByEventId(id)
            const newMarkets = resolvedOdds.markets

            newMarkets.forEach((nMarket) => {
              nMarket.timestamp = nMarket.timestamp
                ? new Date(nMarket.timestamp).getTime()
                : newDataTimestamp
              const oMarket = oldMarkets.find(
                (m) =>
                  m.id === nMarket.id &&
                  m.specifiersString === nMarket.specifiers,
              )

              if (!oMarket) {
                this.getMarketsByEventId(id, [nMarket], oldEvent)
              } else {
                oMarket.update(
                  nMarket,
                  newData.state,
                  false,
                  oddsChangeVisualHandler,
                )
              }
            })
            const allowedMarkets = oldMarkets.filter((market) =>
              this.allowedToStopMarketsStatuses.has(market.status),
            )
            if (allowedMarkets.length !== oldMarkets.length) {
              oldMarkets.splice(0, oldMarkets.length, ...allowedMarkets)
            }
            this.callHandler('onOddsChange', newData)
          })
      } else {
        const oldMarkets = this.getMarketsByEventId(id),
          newMarkets = newData.markets
        newMarkets.forEach((nMarket) => {
          nMarket.timestamp = nMarket.timestamp
            ? new Date(nMarket.timestamp).getTime()
            : newDataTimestamp
          const oMarket = oldMarkets.find(
            (m) =>
              m.id === nMarket.id && m.specifiersString === nMarket.specifiers,
          )
          if (!oMarket) {
            this.getMarketsByEventId(id, [nMarket], oldEvent)
          } else {
            oMarket.update(
              nMarket,
              newData.state,
              false,
              oddsChangeVisualHandler,
            )
          }
        })
        const allowedMarkets = oldMarkets.filter((market) =>
          this.allowedToStopMarketsStatuses.has(market.status),
        )
        if (allowedMarkets.length !== oldMarkets.length) {
          oldMarkets.splice(0, oldMarkets.length, ...allowedMarkets)
        }
        this.callHandler('onOddsChange', newData)
      }
    }
  }

  /**
   * check renames tree items
   */
  checkUpdateNames(newData = {}) {
    const { sport, category, tournament } = newData,
      { menu, lang } = this
    const updateName = function (type, item) {
      const { name, id } = item,
        _obj = menu.findById(type, id),
        newObjName = typeof name === 'object' ? name[lang] || name.en : name
      if (!_obj) return
      if (_obj.name !== newObjName) {
        _obj.name = newObjName
      }
    }
    if (sport && sport.name) {
      updateName('sport', sport)
    }
    if (category && category.name) {
      updateName('category', category)
    }
    if (tournament && tournament.name) {
      updateName('tournament', tournament)
    }
  }

  /**
   * sb_fixture_change handler
   * @param {*} newData
   * @returns
   */

  sb_fixture_change(newData) {
    const id = newData.id,
      initActualLength = this.activeEventsLength
    if (!id) return
    const sportEvents = this.sportEvents
    const oldEvent = sportEvents.getById(id)
    if (oldEvent) {
      const oldStatus = oldEvent.status,
        newStatus = newData.status,
        oldState = oldEvent.state,
        newState = newData.state
      oldEvent.update(newData, true)
      if (oldStatus === 0 && newStatus === 1) {
        sportEvents.moveToLive(id)
      } else if (oldStatus === 1 && newStatus > 1) {
        setTimeout(() => {
          this.removeEvent(id)
        }, this.removeTimeout)
      }
      if (oldState !== newState) {
        const oldMarkets = this.getMarketsByEventId(id)
        oldMarkets.forEach((oMarket) => {
          oMarket.update({}, newState)
        })
      }
      const activeEventsLength = this.activeEventsLength
      if (initActualLength !== activeEventsLength) {
        this.callHandler('onActualLengthChange', activeEventsLength)
      }
      if (oldEvent.state < 1) {
        this.callHandler('onEventChangeState', oldEvent)
        const tournamentId = oldEvent.tournament_id
        const eventsByTournamentId = sportEvents.byTournamentId(tournamentId)
        if (!(eventsByTournamentId && eventsByTournamentId.length)) {
          this.menu.removeTournament(tournamentId)
          this.callHandler('onActualLengthChange', activeEventsLength)
        }
      }
    } else if (this.checkSportEvent(newData).result) {
      this.addSportEventToActual(newData, true)
    }
    this.checkUpdateNames(newData)
  }

  initMenu(sbActualDays = 0) {
    return new Promise((resolve, reject) => {
      let { lang, client } = this
      if (lang === 'ru') {
        lang = 'ru2'
      }
      const treeQuery = lang === 'ru2' ? { lang: 'ru' } : { lang }
      treeQuery.fallbackLang = this.getFallbackLangForLang(treeQuery.lang)
      if (sbActualDays) {
        treeQuery.scheduled = new Date(
          new Date().getTime() + sbActualDays * 24 * 3600 * 1000,
        ).getTime()
      }
      const statusesLang = lang === 'ru2' ? '' : lang
      Promise.all([
        findAll(
          'findSports',
          {
            pageSize: 1000,
            withProps: ['matchStatuses'],
            fields: ['id', 'matchStatuses'],
            lang: statusesLang,
            fallbackLang: this.getFallbackLangForLang(statusesLang),
          },
          1,
          [],
          client,
        ),
        client.getActualTree(treeQuery),
      ])
        .then(([statusesR, menuR]) => {
          this.customMenu.setSportsbookTree(JSON.parse(JSON.stringify(menuR)))
          this.customMenu.init()
          // menuR = menuR.slice(0,1)
          const menuTree = menuR.map((sport) => {
            const statuses = {
              ...(statusesR.find((s) => s.id === sport.id) || {}),
            }
            if (lang === 'ru2') {
              statuses.matchStatuses = statuses.matchStatuses.map((mcsts) => {
                let { abbreviation, description } = mcsts
                if (abbreviation) {
                  abbreviation = abbreviation['ru2'] || abbreviation['ru']
                }
                if (description) {
                  description = description['ru2'] || description['ru']
                }
                return {
                  ...mcsts,
                  abbreviation,
                  description,
                }
              })
            }
            return {
              ...sport,
              ...statuses,
            }
          })
          return menuTree
        })
        .then((resp) => {
          const menu = resp
            .map((sport) => {
              const categories = sport.categories.slice().sort((a, b) => {
                const [aName, bName] = [a.name, b.name]
                if (aName > bName) {
                  return 1
                } else {
                  return -1
                }
              })
              return new Sport({
                ...sport,
                categories,
              })
            })
            .slice()
            .sort((a, b) => {
              const [aName, bName] = [a.weight || 9, b.weight || 9]
              if (aName > bName) {
                return 1
              } else {
                return -1
              }
            })
          resolve(menu)
        })
        .catch((e) => {
          console.log(e)
          reject()
        })
    })
  }

  // TODO удалить после переезда админки на вью 3
  getMarketsByEventIdOld(id, markets = [], event, force = false) {
    let marketsArray = this.markets.get(id)
    if (!marketsArray) {
      if (markets.length) {
        this.markets.set(
          id,
          markets.map((m) => new Market(m, event)),
        )
      } else {
        this.markets.set(id, [])
      }
      marketsArray = this.markets.get(id)
    } else {
      if (force) {
        marketsArray.splice(0, marketsArray.length)
        marketsArray.push(...markets.map((m) => new Market(m, event)))
      } else if (markets.length) {
        marketsArray.push(...markets.map((m) => new Market(m, event)))
      }
    }
    return marketsArray
  }

  getMarketsByEventId(id, markets = [], event, force = false) {
    if (isVue2) return this.getMarketsByEventIdOld(id, markets, event, force)

    // two-way ref that syncs with the original property
    let marketsArray = toRef(this.markets, id)

    if (!marketsArray.value) {
      marketsArray.value = []
      if (markets.length) {
        marketsArray.value.push(...markets.map((m) => new Market(m, event)))
      }

      return marketsArray.value
    }

    if (force) {
      marketsArray.value.splice(0, marketsArray.value.length)
      marketsArray.value.push(...markets.map((m) => new Market(m, event)))
    } else if (markets.length) {
      marketsArray.value.push(...markets.map((m) => new Market(m, event)))
    }

    return marketsArray.value
  }

  resetMarketsByEventId(id) {
    const markets = this.getMarketsByEventId(id)
    markets.splice(0, markets.length)
  }

  async downloadAllMarkets(eventId) {
    const event = await this.client.getSportEvent({
      lang: this.lang,
      fallbackLang: this.getFallbackLangForLang(this.lang),
      id: eventId,
      withProps: ['competitors.players', 'markets', 'match.venue'],
    })

    this.sb_odds_change(event)

    return event
  }

  getMenu() {
    const menu = this.menu

    return new Promise((resolve) => {
      if (menu.length) {
        resolve(menu)
      } else {
        menu.onMenuInited = () => {
          resolve(menu)
        }
      }
    })
  }

  removeEvent(id) {
    this.sportEvents.removeById(id)
    this.callHandler('onRemoveById', id)
    this.callHandler('onActualLengthChange', this.activeEventsLength)
  }

  addSportEventToActual(newData, isFixture = false) {
    const id = newData.id
    const { lang, client, menu } = this
    /* enable when it need
		if (this.downloadingSportEvents[id]) {
			this.downloadingSportEvents[id].push(newData)
			return
		} else {
			this.downloadingSportEvents[id] = []
		}*/

    client
      .findActualSportEvents({
        lang,
        fallbackLang: this.getFallbackLangForLang(lang),
        query: { id },
        withProps: ['match.venue', 'markets', 'competitors', 't_settings'],
        withQuery: { markets: { id: { $in: this.actualMarketsIds } } },
      })
      .then(async (resp) => {
        if (Array.isArray(resp) && resp[0]) {
          resp = resp[0]
        }
        if (resp && resp.id === id) {
          const sportId = resp.sport_id,
            tournamentId = resp.tournament_id
          let sport = menu.findById('sport', sportId, [
            'id',
            'name',
            'matchStatuses',
          ])
          if (!sport) {
            const _menu = await this.initMenu()
            sport = _menu.find((s) => s.id === sportId)
            if (sport) {
              menu.addSport(sport)
              this.callHandler('onReinitMenu')
            }
          } else {
            const tournament = menu.findById('tournament', tournamentId, [
              'id',
              'name',
            ])
            if (!tournament) {
              if (isFixture || (newData.tournament && newData.category)) {
                const tournamentFromFixture = newData.tournament,
                  categoryFromFixture = newData.category
                menu.addTournament(
                  tournamentFromFixture,
                  categoryFromFixture,
                  this.lang,
                  resp.weight,
                )

                this.callHandler('onReinitMenu')
              }
            }
          }
          const markets = resp.markets || []
          if (resp.markets) {
            delete resp.markets
          }
          if (markets.length) {
            const event = {
              ...newData,
              ...resp,
            }
            if (sport) {
              this.sportEvents.push(new SportEvent(event, sport))
              // const fixtureChanges = this.downloadingSportEvents[event.id] || []
              // if (fixtureChanges.length) {
              // 	const assignedFixtureChange = Object.assign({}, ...fixtureChanges)
              // 	this.sb_fixture_change(assignedFixtureChange)
              // 	this.downloadingSportEvents[event.id] = null
              // }
              this.getMarketsByEventId(id, markets, event, true)
              this.callHandler('onActualLengthChange', this.activeEventsLength)
            }
          }
          this.callHandler('onEventChangeState', resp)
        } else {
          // this.downloadingSportEvents[id] = null
        }
      })
      .catch((e) => {
        console.log('eee', e)
        // reject(e)
      })
  }
  /**
   * sb_bet_stop handler
   * @param {*} newData
   * @returns
   */
  sb_bet_stop({
    activeMarketsCount = 0,
    id: sportEventId,
    market_status = 0,
    markets = [],
    provider,
    timestamp,
  }) {
    if (!sportEventId) return
    const sportEvent = this.sportEvents.getById(sportEventId)
    if (!sportEvent) return

    sportEvent.activeMarketsCount = activeMarketsCount
    const oldMarkets = this.getMarketsByEventId(sportEventId)

    if (markets.length) {
      markets.forEach(
        ({
          id: newMarketId,
          specifiers: newMarketSpecifiers,
          provider: newMarketProvider,
        }) => {
          const oldMarket = oldMarkets.find(
            (market) =>
              market.id === newMarketId &&
              market.specifiersString === newMarketSpecifiers,
          )
          if (
            oldMarket &&
            this.allowedToStopMarketsStatuses.has(oldMarket.status)
          ) {
            oldMarket.update({
              status: market_status,
              provider: newMarketProvider,
              timestamp,
            })
          }
        },
      )
      const allowedMarkets = oldMarkets.filter((market) =>
        this.allowedToStopMarketsStatuses.has(market.status),
      )
      if (allowedMarkets.length !== oldMarkets.length) {
        oldMarkets.splice(0, oldMarkets.length, ...allowedMarkets)
      }
    } else {
      if (market_status === 0) {
        oldMarkets.splice(0, oldMarkets.length)
      } else {
        oldMarkets.forEach((oMarket) => {
          if (this.allowedToStopMarketsStatuses.has(oMarket.status)) {
            oMarket.update({
              status: market_status,
              provider,
              timestamp,
            })
          }
        })
      }
    }
  }

  checkSportEvent(sportEvent) {
    if (typeof sportEvent.provider !== 'number') {
      return { result: false, reason: 'no_provider' }
    }
    if (![0, 1].includes(sportEvent.status)) {
      return {
        result: false,
        reason: 'incorrect_status',
        meta: { status: sportEvent.status },
      }
    }
    if (!sportEvent.activeMarketsCount) {
      return { result: false, reason: 'no_active_markets' }
    }
    if (
      sportEvent.status === SPORT_EVENT.STATUS.LIVE &&
      sportEvent.liveodds === 'not_available'
    ) {
      return { result: false, reason: 'liveodds_not_available' }
    }
    if (typeof sportEvent.weight !== 'number') {
      return { result: false, reason: 'no_sport_event_weight' }
    }
    if (sportEvent.state === SPORT_EVENT.STATE.DISABLED) {
      return { result: false, reason: 'zero_sport_event_state' }
    }
    if (!(sportEvent.tournament && sportEvent.tournament.state)) {
      return { result: false, reason: 'zero_tournament_state' }
    }
    if (!(sportEvent.category && sportEvent.category.state)) {
      return { result: false, reason: 'zero_category_state' }
    }
    if (!(sportEvent.sport && sportEvent.sport.state)) {
      return { result: false, reason: 'zero_sport_state' }
    }
    return { result: true }
  }

  get activeEventsLength() {
    const allowedStates = [1, 100, -10, -20]
    return this.sportEvents.filter(
      (spEvent) => spEvent.status >= 0 && allowedStates.includes(spEvent.state),
    ).length
  }

  getFallbackLangForLang(lang) {
    return this.fallbackLang === lang ? 'en' : this.fallbackLang
  }
}

export default MarketsStore

export * from './constants'
export * from './classes/index'
