import SportEventMatch from './SportEventMatch'
import { reactive, isVue2 } from 'vue-demi'
import { SB_OVERTIME_STATUSES, CYBER_SPORT_ID } from '../constants'

/**
 * dynamic properties
 */
const customConfigurableValues = {
  activeMarketsCount: true,
  match: true,
  matchStatusObj: true,
  provider: true,
  state: true,
  status: true,
  weight: true,
  video: true,
  dicePlatformId: true,
}

const getParentState = (event, levels = [], state = 1) => {
  if (state !== 1 || !levels.length) {
    return state
  }
  const level = levels[0]
  const parent = event[level]

  if (parent) {
    const parentState = parent.state
    if (!isNaN(parentState) && parentState !== 1) {
      return parentState
    }
  }
  levels.splice(0, 1)
  return getParentState(event, levels, state)
}

export default function (event, sport) {
  const sportId = event.sport_id
  const tournamentId = event.tournament_id
  const categoryId = event.category_id
  const match = event.match || {}
  const isCyberSport = sportId === CYBER_SPORT_ID

  event.sportId = sportId
  event.categoryId = categoryId
  event.tournamentId = tournamentId
  event.url = isCyberSport
    ? `/cybersport/${categoryId}/${tournamentId}/${event.type}/${event.id}`
    : `/sport/${sportId}/${categoryId}/${tournamentId}/${event.type}/${event.id}`
  event.mobileUrl = `/mobile${event.url}`
  event.match = isVue2
    ? new SportEventMatch(match)
    : reactive(new SportEventMatch(match))

  /**
   *  set fast get competitors
   */

  for (const competitor of event.competitors || []) {
    event[competitor.qualifier] = competitor
  }
  /**
   * match status description & abbreviation
   */
  const matchStatus = match.status || 0
  const _findedMatchStatus = (sport.matchStatuses || []).find(
    (status) => status.status === matchStatus,
  )
  /**
   * всегда нужно знать первый временной промежуток матча
   * для вывода в предросписи
   */
  const firstPeriodStatus = (sport.matchStatuses || []).find(
    (item) =>
      item?.period_number === 1 &&
      !Object.values(SB_OVERTIME_STATUSES).includes(item?.status),
  )

  const matchStatusObj = _findedMatchStatus
    ? Object.assign(
        { firstPeriodStatus: firstPeriodStatus?.description ?? '' },
        _findedMatchStatus,
      )
    : {}
  event.matchStatusObj = matchStatusObj

  /**
   * other getters
   */
  event.venue = match.venue
  event.willLive = event.liveodds === 'booked'

  return new Proxy(isVue2 ? event : reactive(event), {
    get(obj, prop) {
      switch (prop) {
        case 'getOwnPropertyDescriptorModified':
          return (target, prop) => {
            const descriptorValue = Reflect.getOwnPropertyDescriptor(
              target,
              prop,
            )
            const configurable = customConfigurableValues[prop]
            if (descriptorValue && !configurable) {
              descriptorValue.configurable = false
            }
            return descriptorValue
          }
        case 'update':
          return (newData, isFixtureChange = false) => {
            const timestamp = new Date(newData.timestamp).getTime()
            if (timestamp < obj.timestamp && newData.provider === obj.provider)
              return

            const parentState = isFixtureChange
              ? getParentState(newData, ['tournament', 'category', 'sport'], 1)
              : 1
            const oldMatchStatus = obj.match?.status

            for (const key in newData) {
              const newValue = newData[key]
              if (key === 'markets') {
                continue
              } else if (key === 'state') {
                if (isFixtureChange) {
                  if (obj[key] !== newValue) {
                    obj[key] = newValue
                  }
                  if (!parentState || obj[key] > parentState) {
                    obj[key] = parentState
                  }
                }
              } else if (
                key === 'match_status' &&
                obj.match.status !== newValue
              ) {
                obj.match.status = newValue
              } else if (key === 'match_timestamp') {
                obj.match.timestamp = newValue
              } else if (key === 'match_stats') {
                if (!obj.match || !obj.match?.stats) {
                  console.log(
                    `Match or match.stats doesn't exist. new value`,
                    JSON.stringify({
                      match: obj.match,
                      stats: obj.match?.stats,
                    }),
                  )
                }
                Object.assign(obj.match.stats, newValue)
              } else if (
                Object.prototype.hasOwnProperty.call(obj, key) &&
                newValue &&
                typeof newValue === 'object'
              ) {
                try {
                  if (!obj[key]) obj[key] = {}
                  Object.assign(obj[key], newValue)
                } catch (e) {
                  console.error(
                    `Error while processing key '${key}'`,
                    e,
                    obj,
                    newValue,
                  )
                }
              } else if (obj[key] !== newValue) {
                obj[key] = newValue
              }
            }
            // update matchStatusObj
            const newMatchStatus = obj.match?.status
            if (oldMatchStatus !== newMatchStatus) {
              const __findedMatchStatus = (sport.matchStatuses || []).find(
                (status) => status.status === newMatchStatus,
              )
              const newMatchStatusObj = __findedMatchStatus
                ? Object.assign({}, __findedMatchStatus)
                : {}
              if (newMatchStatusObj) {
                Object.assign(obj.matchStatusObj, newMatchStatusObj)
              }
            }
          }
        default:
          return obj[prop]
      }
    },
  })
}
