import type { Ref } from 'vue'

export type Platform = 'mobile' | 'desktop'

export interface UsePlatformReturn {
  platform: Ref<Platform>
  isMobile: Ref<boolean>
  isDesktop: Ref<boolean>
}

export function usePlatform(): UsePlatformReturn {
  const route = useRoute()

  const platform = computed(() =>
    route.path.includes('mobile') ? 'mobile' : 'desktop',
  )

  const isMobile = computed(() => platform.value === 'mobile')
  const isDesktop = computed(() => platform.value === 'desktop')

  return { isMobile, isDesktop, platform }
}
