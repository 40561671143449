import { setupWorker } from 'msw/browser'
import mocks from '../mocks'

export default defineNuxtPlugin(async () => {
  const isEnabledMocks = !!useRuntimeConfig().public.mocksEnabled
  if (!isEnabledMocks) return

  const worker = setupWorker(...mocks)

  await worker.start({
    onUnhandledRequest: 'bypass',
  })
})
