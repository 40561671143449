export default defineNuxtPlugin((nuxt) => {
  nuxt.hook('app:mounted', () => {
    const config = useRuntimeConfig()
    const apiKey = config.public.recaptchaKey

    useHead(() => ({
      script: [
        {
          src: `https://www.google.com/recaptcha/enterprise.js?render=${apiKey}`,
          async: true,
        },
      ],
    }))
  })
})
