export const MARKETS_OTHER_IDS: readonly number[] = [
  1, 305, 375, 333, 330, 61, 7, 406, 458, 496, 210,
]

export const TENNIS_SCORES_SPORTS_IDS: readonly number[] = [
  25, 167, 172, 9, 165, 145, 10, 7, 16,
]

export const CYBER_SPORT_ID = 100248 as const
export const CYBER_SPORT_CATEGORIES: readonly number[] = [1335, 2125, 2131]

export const SPORT_EVENT_STATUSES_ENUM = {
  prematch: 0,
  live: 1,
  suspended: 2,
  ended: 3,
  closed: 4,
  canceled: 5,
  delayed: 6,
  interrupted: 7,
  postponed: 8,
  abandoned: 9,
} as const

export const EVENT_STATES = {
  ACTIVE: 1,
  INACTIVE: 0,
  BET_STOP_BY_ADMIN_BK: -10,
  BET_STOP_BY_ADMIN_LM: -11,
  BET_STOP_BY_ADMIN_SB: -20,
  DELETED: -100,
  /*
    RETURNED - событие было возвращено после удаления / остановки
  */
  RETURNED: 100,
}
export const BET_STOP_STATES = [
  EVENT_STATES.BET_STOP_BY_ADMIN_BK,
  EVENT_STATES.BET_STOP_BY_ADMIN_LM,
  EVENT_STATES.BET_STOP_BY_ADMIN_SB,
] as const
export const DELETED_STATES = [EVENT_STATES.DELETED] as const

export const SB_OVERTIME_STATUSES = {
  OVERTIME: 40,
  FIRST_OVERTIME: 41,
  SECOND_OVERTIME: 42,
} as const

export const EVENT_ALLOWED_STATES: readonly number[] = [
  EVENT_STATES.ACTIVE,
  EVENT_STATES.RETURNED,
  EVENT_STATES.BET_STOP_BY_ADMIN_BK,
  EVENT_STATES.BET_STOP_BY_ADMIN_LM,
  EVENT_STATES.BET_STOP_BY_ADMIN_SB,
]

export const SPORT_IDS = {
  SKIING: 2,
  AUSTRALIAN_FOOTBALL: 6,
  BADMINTON: 7,
  BALL_HOCKEY: 8,
  BASEBALL: 9,
  BASKETBALL: 10,
  BASKETBALL_STREET: 11,
  SOCCER_BEACH: 14,
  VOLLEYBALL_BEACH: 16,
  BIATHLON: 17,
  BOWLES: 20,
  BOXING: 21,
  CRICKET: 25,
  CURLING: 27,
  DARTS: 33,
  FORMULA: 80,
  FIELD_HOCKEY: 83,
  FLOORBALL: 87,
  AMERICAN_FOOTBALL: 88,
  FOOTSAL: 92,
  GOLF: 96,
  HANDBALL: 99,
  ICE_HOCKEY: 102,
  KABADDI: 108,
  MMA: 111,
  MOTOSPORT: 118,
  RUGBY: 135,
  SNOOKER: 145,
  SOCCER: 149,
  SQUASH: 157,
  TABLE_TENNIS: 165,
  TENNIS: 167,
  VOLLEYBALL: 172,
  WATERPOLO: 173,
  MARTIAL_ARTS: 100252,
  HORSE_SPORTS: 100253,
  CYCLING: 100257,
}

export const PLAYERS_IN_TEAM_DEFAULT = 5
export const PLAYERS_IN_TEAM_BY_SPORT_ID = {
  [SPORT_IDS.HANDBALL]: 6,
  [SPORT_IDS.ICE_HOCKEY]: 5,
}

export const PLAYERS_IN_TEAM_HOCKEY_OVERTIME = 3

export const TOURNAMENTS = {
  BELARUSIAN_FOOTBALL_CHAMPIONSHIP: 91,
  KHL: 104,
  RUSSIAN_PREMIER_LEAGUE: 93,
  KAZAKH_PREMIER_LEAGUE: 2619,
  ENGLISH_PREMIER_LEAGUE: 92,
  BUNDESLIGA: 106,
  LA_LIGA: 109,
  SERIE_A: 95,
  LIGUE_1: 105,
  NHL: 96,
  HOCKEY_ALL_STARS_GAME: 231,
  NBA: 88,
  BASKETBALL_ALL_STARS_GAME: 334,
} as const

export const SPORTS = {
  FOOTBALL: 149,
  TENNIS: 167,
  HOCKEY: 102,
  CRICKET: 25,
  KABADDI: 108,
} as const

export const PERIOD_TIME_LENGTH = {
  SOCCER: 45,
} as const

export const SPORT_EVENT_CUSTOM_TAGS = {
  CYBER_FIFA: 'cyberfifa',
  UFC: 'ufc',
} as const

export const SPORT_MENU_ITEMS = {
  CATEGORY: 'category',
  SPORT: 'sport',
  TOURNAMENT: 'tournament',
  CUSTOM: 'custom',
} as const

export const PERIOD_SPECIFIERS: readonly string[] = [
  'half',
  'setnr',
  'quarternr',
  'periodnr',
  'setnr',
  'mapnr',
  'gamenr',
  'inningnr',
  'overnr',
  'framenr',
  'roundnr',
  'deliverynr',
]

export const SPECIFIERS_BY_SPORT: Record<
  number,
  (typeof PERIOD_SPECIFIERS)[number]
> = {
  167: 'setnr',
  10: 'quarternr',
  102: 'periodnr',
  172: 'setnr',
  145: 'framenr',
  9: 'inningnr',
  100248: 'mapnr',
  165: 'gamenr',
  16: 'setnr',
}

export const OUTCOMES_IN_SELECT_CYBERSPORT: readonly number[] = [301208, 301221]

export const PENALTY_STATUS = 50

export const SB_PENALTY_STATUSES_BY_SPORT = {
  [SPORT_IDS.SOCCER]: [PENALTY_STATUS],
  [SPORT_IDS.ICE_HOCKEY]: [PENALTY_STATUS],
}

export const SPORTS_WITH_GOAL_NOTIFICATIONS = new Set([8, 92, 99, 149])

export const MARKET_STATES = {
  ACTIVE: 1,
  BETSTOP: -1,
  INACTIVE: 0,
  RETURNED: 100,
}

export const MARKET_ALLOWED_STATES = [
  MARKET_STATES.ACTIVE,
  MARKET_STATES.BETSTOP,
] as const

export const YES_NO_MARKETS = [304, 26, 452, 29, 75, 95, 195]

export const SPORTS_WITH_LONG_PERIOD = [
  SPORT_IDS.BASKETBALL,
  SPORT_IDS.ICE_HOCKEY,
  SPORT_IDS.BASEBALL,
  SPORT_IDS.CRICKET,
  SPORT_IDS.RUGBY,
  SPORT_IDS.AMERICAN_FOOTBALL,
  SPORT_IDS.HANDBALL,
]

export const WITH_SCORES_MARKET_IDS = [
  889, 890, 768, 769, 770, 771, 772, 773, 774, 780, 790, 791, 792, 793, 759,
  760, 761, 762, 763, 764, 765, 766, 767, 794, 795, 796, 781, 782, 783, 784,
  785, 786, 787, 789, 778, 775, 770, 776, 777,
]
export const WITH_EXODUS_MARKET_IDS = [888, 891]

export const BANNED_MARKETS = new Set([
  11, 13, 64, 294, 86, 464, 465, 466, 475, 484, 612, 302, 316, 459, 554, 613,
  12,
])
export const BANNED_MARKETS_WITH_SPECIFIERS = new Set([
  16, 66, 88, 117, 120, 165, 176, 188, 203, 223, 231, 256, 275, 280, 303, 327,
  331, 366, 371, 383, 460, 477, 486, 493, 500, 555, 567, 574, 581, 614, 627,
  729, 730, 744, 746, 410,
])
