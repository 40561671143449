import type { FetchOptions } from 'ofetch'

export function useApiConfig() {
  const optionsFromConfig = useRuntimeConfig().public.api as any
  const bus = useApiEventBus()
  const requestURL = useRequestURL()

  const platform = requestURL.pathname.includes('mobile')
    ? 'mobilesite'
    : 'desktopsite'

  function normalizeBaseURL(baseURL: string) {
    return baseURL.startsWith('/') ? `${requestURL.origin}${baseURL}` : baseURL
  }

  function createApiConfig(options: FetchOptions = {}): FetchOptions {
    const mergedConfig: FetchOptions = {
      ...optionsFromConfig,
      ...options,
      credentials: 'include',
      onRequest: (context) => {
        bus.emit('request', context)
      },
      onResponse: (context) => {
        bus.emit('response', context)
      },
      onRequestError: (context) => {
        bus.emit('requestError', context)
      },
      onResponseError: (context) => {
        bus.emit('responseError', context)
      },
    }

    mergedConfig.baseURL = mergedConfig.baseURL
      ? normalizeBaseURL(mergedConfig.baseURL)
      : normalizeBaseURL('/')

    mergedConfig.headers = {
      ...mergedConfig.headers,
      'X-platform': platform,
    }

    return mergedConfig
  }

  return { createApiConfig }
}
