import revive_payload_client_YTGlmQWWT5 from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_xKuw1km5sV from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/apps/site/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_N1rIkEnC9Q from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_dOTnCc6TUQ from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_SeG0EjL5Ec from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import init_lw4AB3Lbis from "/app/packages/st-i18n/plugins/init.ts";
import apiEventBusPlugin_6fcmhSsfcj from "/app/packages/st-api/plugins/apiEventBusPlugin.ts";
import mswPlugin_client_aiRK4yvCY8 from "/app/packages/st-api/plugins/mswPlugin.client.ts";
import socketIo_2chAVMxtF0 from "/app/packages/st-nuxt-sockets/plugins/socketIo.ts";
import verificationModalsPlugin_client_penz9KGRm2 from "/app/entities/st-verification/plugins/verificationModalsPlugin.client.ts";
import ipqs_client_8xWDgrlfma from "/app/packages/st-ipqs/plugins/ipqs.client.ts";
import intercom_client_YH0KQbdFwS from "/app/packages/st-intercom/plugins/intercom.client.ts";
import recaptcha_TtT6SAJH6r from "/app/packages/st-nuxt-recaptcha/plugins/recaptcha.ts";
import autoAuthPlugin_client_MeYY1VGyEO from "/app/entities/st-user/plugins/autoAuthPlugin.client.ts";
import referralCodePlugin_client_nTHPg3NoU4 from "/app/entities/st-user/plugins/referralCodePlugin.client.ts";
import showTelegramAuth_client_jLPtSlc6Ga from "/app/entities/st-user/plugins/showTelegramAuth.client.ts";
import telegramBackButtonWatcher_client_sEwLn03ApV from "/app/entities/st-user/plugins/telegramBackButtonWatcher.client.ts";
import telegram_auth_client_gg5U5OIe02 from "/app/packages/st-telegram/plugins/telegram-auth.client.ts";
import telegram_init_client_9mOgZ7iRRu from "/app/packages/st-telegram/plugins/telegram-init.client.ts";
import partner_client_7LhgciUFav from "/app/packages/st-partner/plugins/partner.client.ts";
import gtm_client_cFQaMrNz9U from "/app/packages/st-gtm/plugins/gtm.client.ts";
import sentry_client_jy28sex9Fj from "/app/packages/st-nuxt-sentry/plugins/sentry.client.ts";
import errorHandler_x1Y4n5NP4w from "/app/apps/site/plugins/errorHandler.ts";
import ipRestrictionPlugin_ArlEhaHSEO from "/app/apps/site/plugins/ipRestrictionPlugin.ts";
import markets_store_QwbHfVcmtZ from "/app/packages/st-nuxt-markets-store/plugins/markets-store.ts";
import init_X5h4CJ85GB from "/app/packages/st-core/plugins/init.ts";
import userLogoutPlugin_client_tmys9NJmps from "/app/entities/st-user/plugins/userLogoutPlugin.client.ts";
import initHomeStore_LaEaXqulxe from "/app/entities/st-bookmaker/plugins/initHomeStore.ts";
import paymentStoresInit_client_RGCSvMhblU from "/app/entities/st-payments/plugins/paymentStoresInit.client.ts";
import initCouponStore_RGHJYitbbI from "/app/entities/st-coupon/plugins/initCouponStore.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  plugin_vue3_xKuw1km5sV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  composition_N1rIkEnC9Q,
  i18n_dOTnCc6TUQ,
  chunk_reload_client_SeG0EjL5Ec,
  check_outdated_build_client_LIYcCMJD18,
  init_lw4AB3Lbis,
  apiEventBusPlugin_6fcmhSsfcj,
  mswPlugin_client_aiRK4yvCY8,
  socketIo_2chAVMxtF0,
  verificationModalsPlugin_client_penz9KGRm2,
  ipqs_client_8xWDgrlfma,
  intercom_client_YH0KQbdFwS,
  recaptcha_TtT6SAJH6r,
  autoAuthPlugin_client_MeYY1VGyEO,
  referralCodePlugin_client_nTHPg3NoU4,
  showTelegramAuth_client_jLPtSlc6Ga,
  telegramBackButtonWatcher_client_sEwLn03ApV,
  telegram_auth_client_gg5U5OIe02,
  telegram_init_client_9mOgZ7iRRu,
  partner_client_7LhgciUFav,
  gtm_client_cFQaMrNz9U,
  sentry_client_jy28sex9Fj,
  errorHandler_x1Y4n5NP4w,
  ipRestrictionPlugin_ArlEhaHSEO,
  markets_store_QwbHfVcmtZ,
  init_X5h4CJ85GB,
  userLogoutPlugin_client_tmys9NJmps,
  initHomeStore_LaEaXqulxe,
  paymentStoresInit_client_RGCSvMhblU,
  initCouponStore_RGHJYitbbI
]