import type { Notification, BonusNotification } from '../types'

export function useBonusNotification(notification: Ref<Notification>) {
  const { t } = useI18n()

  function isBonusNotificationGuard(
    notificationItem: Ref<Notification>,
  ): notificationItem is Ref<BonusNotification> {
    return 'bonusProgramType' in notificationItem.value.data
  }

  const bonusTitle = computed(() => {
    if (!isBonusNotificationGuard(notification)) return ''

    return (
      {
        cashback: t('notifications.subject.cashback'),
        reload: t('notifications.subject.reload'),
        rakeBack: t('notifications.subject.rakeback'),
        ratRace: t('notifications.subject.ratRace'),
        quest: t('notifications.subject.quest'),
        bonusForDeposit: t('notifications.subject.bonusForDeposit'),
      }[notification.value.data.bonusProgramType] ??
      t('notifications.subject.bonus')
    )
  })

  const bonusType = computed(() =>
    isBonusNotificationGuard(notification)
      ? notification.value.data.bonusProgramType
      : '',
  )

  const bonusIcon = computed(() => {
    if (notification.value.type === 'bonusForDepositProgramUsersCreated') {
      return 'gift-solid'
    }

    if (bonusType.value) {
      return (
        {
          ratRace: 'cup-solid' as const,
          cashback: 'cashback' as const,
          rakeBack: 'cashback' as const,
          reload: 'gift-solid' as const,
          quest: 'award' as const,
          bonusForDeposit: 'gift-solid' as const,
        }[bonusType.value] ?? 'gift-solid'
      )
    }

    return ''
  })

  return {
    bonusTitle,
    bonusType,
    bonusIcon,
  }
}
