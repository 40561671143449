import { type Ref, ref, onBeforeUnmount, watch } from 'vue'

const FOCUS_ELEMENTS: any = [
  'a[href]',
  'area[href]',
  'input:not([disabled]):not([type="hidden"]):not([aria-hidden])',
  'select:not([disabled]):not([aria-hidden])',
  'textarea:not([disabled]):not([aria-hidden])',
  'button:not([disabled]):not([aria-hidden])',
  'iframe',
  'object',
  'embed',
  '[contenteditable]',
  '[tabindex]:not([tabindex^="-"])',
]

export function useFocusTrap(content: Ref<HTMLDivElement | undefined>) {
  const starter = ref()

  const getNodesArray = () => {
    const nodes = content.value?.querySelectorAll(FOCUS_ELEMENTS)
    return Array.prototype.slice.call(nodes)
  }

  const focusCatcher = (event: KeyboardEvent) => {
    const nodesArray = getNodesArray()
    if (!content.value?.contains(document.activeElement)) {
      nodesArray[0].focus()
      event.preventDefault()
      return
    }

    const focusedItemIndex = nodesArray.indexOf(document.activeElement)

    if (event.shiftKey && focusedItemIndex === 0) {
      nodesArray[nodesArray.length - 1].focus()
      event.preventDefault()
    }

    if (!event.shiftKey && focusedItemIndex === nodesArray.length - 1) {
      nodesArray[0].focus()
      event.preventDefault()
    }
  }

  const listener = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      focusCatcher(event)
    }
  }

  onBeforeUnmount(() => {
    document.removeEventListener('keydown', listener)
  })

  watch(
    () => content.value,
    (value: any) => {
      if (value) {
        document.addEventListener('keydown', listener)
        starter.value = document.activeElement
      } else {
        document.removeEventListener('keydown', listener)
        starter.value?.focus()
      }
    },
  )
}
