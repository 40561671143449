import { setUtmCookie, getCookie } from '../utils/setUtmCookies'

export function usePartnerProgram() {
  const stFetch = useRawStFetch()

  function visitByPartner() {
    const params = new URLSearchParams(globalThis.location.search)
    const body = {
      partnerId: params.get('a_aid') || getCookie('PAPaid'),
      bannerId: params.get('a_bid') || getCookie('PAPbid'),
      campaignId: params.get('a_cid') || getCookie('PAPcid'),
      data1: params.get('data1') || getCookie('PAPdata1'),
      data2: params.get('data2') || getCookie('PAPdata2'),
      channel: params.get('chan') || getCookie('PAPchan'),
    }

    const referrer = params.get('referrer') || document.referrer

    stFetch('/partner-program/post-affiliate/visit', {
      method: 'post',
      body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Partner-Referrer': referrer,
      },
    })
  }

  function saveUtmCookies() {
    const params = new URLSearchParams(globalThis.location.search)
    const utmParams = {
      utm_campaign: params.get('utm_campaign') ?? '',
      utm_medium: params.get('utm_medium') ?? '',
      utm_source: params.get('utm_source') ?? '',
      utm_content: params.get('utm_content') ?? '',
    }

    setUtmCookie(utmParams)
  }

  return {
    visitByPartner,
    saveUtmCookies,
  }
}
