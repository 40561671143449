import { useSettingsStore } from '@st/core/stores/useSettingsStore'
import { type CouponOutcome, useCouponStore } from '../stores/useCouponStore'

export function useGlobalOutcomeButtonClickHandler() {
  const couponStore = useCouponStore()
  const { settings } = storeToRefs(useSettingsStore())
  const toast = useToast()
  const { t } = useI18n()

  function tryToAddOutcomeToCoupon(outcome: CouponOutcome) {
    if (couponStore.hasOutcome(outcome.uniqId)) {
      couponStore.removeOutcome(outcome.uniqId)
      return
    }

    const maxOutcomesCount = settings.value?.maxBetOutcomesNumberInExpress
    if (maxOutcomesCount && couponStore.outcomeCount >= maxOutcomesCount) {
      toast.open({
        label: t('coupon.errors.tooManyOutcomes', { maxOutcomesCount }),
        type: 'negative',
      })
      return
    }

    const outcomeFromSameEvent = couponStore.outcomes.find(
      (otherOutcome) => otherOutcome.eventId === outcome.eventId,
    )
    if (outcomeFromSameEvent) {
      couponStore.removeOutcome(outcomeFromSameEvent.uniqId)
    }

    couponStore.addOutcome(outcome)
  }

  function handleClick(mouseEvent: MouseEvent) {
    const dataset: Record<string, string> =
      (mouseEvent as any)?.target?.dataset ?? {}

    if (dataset.todo !== 'to_coupon') return

    const { eventId, marketId, marketSpecifiers, uniqId, outcomeId } = dataset

    const outcome: CouponOutcome = {
      eventId: Number(eventId),
      marketId: Number(marketId),
      marketSpecifiers,
      uniqId,
      outcomeId: Number(outcomeId),
    }

    tryToAddOutcomeToCoupon(outcome)
  }

  return { handleClick }
}
