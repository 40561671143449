import { reactive, isVue2 } from 'vue-demi'

const _roleNames = {
  home: 'home',
  away: 'away',
  draw: 'draw',
  under: 'under',
  over: 'over',
}

const outcomeRoles = new Map([
  // home
  [1, _roleNames.home],
  [4, _roleNames.home],
  [6, _roleNames.home],
  [780, _roleNames.home],
  [948, _roleNames.home],
  [1041, _roleNames.home],
  [1813, _roleNames.home],
  [1711, _roleNames.home],
  // draw
  [2, _roleNames.draw],
  [776, _roleNames.draw],
  [782, _roleNames.draw],
  [949, _roleNames.draw],
  [1043, _roleNames.draw],
  [1717, _roleNames.draw],
  [1803, _roleNames.draw],
  [1819, _roleNames.draw],
  [1834, _roleNames.draw],
  [1967, _roleNames.draw],
  [1712, _roleNames.draw],
  // away
  [3, _roleNames.away],
  [5, _roleNames.away],
  [8, _roleNames.away],
  [778, _roleNames.away],
  [950, _roleNames.away],
  [1042, _roleNames.away],
  [1815, _roleNames.away],
  [1713, _roleNames.away],
  // handicaps
  [1714, _roleNames.home],
  [1715, _roleNames.away],
  // total under
  [13, _roleNames.under],
  [92, _roleNames.under],
  [96, _roleNames.under],
  [794, _roleNames.under],
  [798, _roleNames.under],
  [802, _roleNames.under],
  [656, _roleNames.under],
  [658, _roleNames.under],
  [939, _roleNames.under],
  [975, _roleNames.under],
  [976, _roleNames.under],
  [1040, _roleNames.under],
  [1724, _roleNames.under],
  [1725, _roleNames.under],
  [1726, _roleNames.under],
  [1836, _roleNames.under],
  [1837, _roleNames.under],
  [1838, _roleNames.under],
  [1839, _roleNames.under],
  [1840, _roleNames.under],
  [1841, _roleNames.under],
  [1842, _roleNames.under],
  [1843, _roleNames.under],
  [1844, _roleNames.under],
  // total over
  [12, _roleNames.over],
  [90, _roleNames.over],
  [94, _roleNames.over],
  [796, _roleNames.over],
  [800, _roleNames.over],
  [804, _roleNames.over],
  [660, _roleNames.over],
  [662, _roleNames.over],
  [941, _roleNames.over],
  [973, _roleNames.over],
  [974, _roleNames.over],
  [1039, _roleNames.over],
  [1727, _roleNames.over],
  [1728, _roleNames.over],
  [1729, _roleNames.over],
  [1845, _roleNames.over],
  [1846, _roleNames.over],
  [1847, _roleNames.over],
  [1848, _roleNames.over],
  [1849, _roleNames.over],
  [1850, _roleNames.over],
  [1851, _roleNames.over],
  [1852, _roleNames.over],
  [1853, _roleNames.over],
])

const getOutcomeActive = function (outcome) {
  const { state, sbActive, odds } = outcome
  const statuses = {
    0: false,
    '-10': true,
    '-11': false,
    '-20': true,
    '-100': false,
  }
  if (odds === 1) return false
  if (!sbActive) return false
  const statusByState = statuses[String(state)]
  if (typeof statusByState !== 'undefined') return statusByState
  return sbActive
}

/**
 * get computed own state to manage classes
 * @param {} outcome
 * @returns ['active', 'suspend', 'disable']
 * 'active' - show and accept to coupon
 * 'suspend' - show (as disable) and not accept to coupon
 * 'disable' - not show and not accept
 */
const getComputedState = function (outcome) {
  const { state, sbActive, odds } = outcome
  if (odds === 1 || !sbActive) return 'disable'
  if (~[-20, -10, 0].indexOf(state)) return 'suspend'
  if (state < -1) return 'disable'
  return 'active'
}

const getDoubleOdds = function (outcome) {
  const { odds, state } = outcome
  if (~[-100, 0].indexOf(state) || odds === 1) return '–'
  let _out = String(odds)
  if (!_out.includes('.') && _out.length < 3) {
    _out += '.'
  }
  _out += '00'
  _out = _out.slice(0, _out.includes('.') ? 4 : 3)
  if (isNaN(_out)) return '–'
  return _out
}

/**
 * dynamic properties
 */
const customConfigurableValues = {
  active: true,
  state: true,
  name: true,
  odds: true,
  doubleOdds: true,
  computedState: true,
}

export default function (
  { active, id, name = '', odds, team, state, type },
  event,
) {
  const { marketId, specifiersString, eventId } = event
  const eventType = event.type
  const outcome = {
    id: Number(id),
    sbActive: active,
    state,
    odds: Number(odds),
    name,
    team,
    role: outcomeRoles.get(id),
    marketId,
    specifiersString,
    eventId,
    eventType,
    uniqId: `event:${eventId}-market:${marketId}-${specifiersString}-outcome:${id}`,
    type,
  }
  outcome.active = getOutcomeActive(outcome)
  outcome.doubleOdds = getDoubleOdds(outcome)
  outcome.computedState = getComputedState(outcome)

  return new Proxy(isVue2 ? outcome : reactive(outcome), {
    get(target, propKey, receiver) {
      switch (propKey) {
        case 'getOwnPropertyDescriptorModified':
          return (target, prop) => {
            const descriptorValue = Reflect.getOwnPropertyDescriptor(
              target,
              prop,
            )
            const configurable = customConfigurableValues[prop]
            if (descriptorValue && !configurable) {
              descriptorValue.configurable = false
            }
            return descriptorValue
          }
        case 'update':
          return ({ active, odds, name, state }, oddsChangeVisualHandler) => {
            if (!target.name && name) {
              target.name = name
            }
            if (typeof odds !== 'undefined' && target.odds !== odds) {
              const oldOdds = target.odds
              target.odds = odds
              target.doubleOdds = getDoubleOdds(target)
              if (
                oddsChangeVisualHandler &&
                typeof oddsChangeVisualHandler === 'function'
              ) {
                oddsChangeVisualHandler(target.uniqId, odds, oldOdds)
              }
            }
            if (typeof state !== 'undefined' && target.state !== state) {
              target.state = state
            }
            if (typeof active !== 'undefined' && target.sbActive !== active) {
              target.sbActive = active
            }
            const newActive = getOutcomeActive(target)
            if (target.active !== newActive) {
              target.active = newActive
            }
            const newComputedState = getComputedState(target)
            if (target.computedState !== newComputedState) {
              target.computedState = newComputedState
            }
            return target
          }
        case 'updateName':
          return (name) => {
            target.name = name
            return name
          }
        default:
          return Reflect.get(target, propKey, receiver)
      }
    },
  })
}
