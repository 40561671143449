import { ofetch, type FetchOptions, type FetchResponse } from 'ofetch'
import type {
  AnyApiSpec,
  HttpMethod,
  PathParams,
  QueryParams,
  RequestBody,
  ResponseBody,
  ResponseError,
} from './typeHelpers'

export interface TypedFetch<ApiSpec extends AnyApiSpec> {
  <K extends keyof ApiSpec, Method extends HttpMethod & keyof ApiSpec[K]>(
    request: K,
    options: {
      method?: Method
      body?: RequestBody<ApiSpec, K, Method>
      params?: PathParams<ApiSpec, K, Method>
      query?: QueryParams<ApiSpec, K, Method>
      responseType?: ResponseBody<ApiSpec, K, Method>
    } & Omit<
      FetchOptions,
      'method' | 'body' | 'params' | 'query' | 'responseType'
    >,
  ): Promise<ResponseBody<ApiSpec, K, Method>>
  raw<K extends keyof ApiSpec, Method extends HttpMethod & keyof ApiSpec[K]>(
    request: K,
    options: {
      method?: Method
      body?: RequestBody<ApiSpec, K, Method>
      params?: PathParams<ApiSpec, K, Method>
      query?: QueryParams<ApiSpec, K, Method>
      responseType?: ResponseBody<ApiSpec, K, Method>
    } & Omit<
      FetchOptions,
      'method' | 'body' | 'params' | 'query' | 'responseType'
    >,
  ): Promise<FetchResponse<ResponseBody<ApiSpec, K, Method>>>
  native: typeof globalThis.fetch
  create(defaults: FetchOptions): TypedFetch<ApiSpec>
}

/**
 *  Создает типизированную обертку над ofetch
 *  на основе сгенерированной openapi-typescript схемы API
 *
 *  @example
 *  import type { paths } from './admin-openapi-spec'
 *
 *  const adminFetch = createTypedFetch<paths>({
 *    baseURL: '/admin'
 *  })
 *
 *  // Урлы, параметры запроса и ответа - все типизировано
 *  const response = await adminFetch('/foo', { method: 'post' })
 *  */
export function createTypedFetch<ApiSpec extends AnyApiSpec>(
  options: FetchOptions,
): TypedFetch<ApiSpec> {
  return ofetch.create(options) as TypedFetch<ApiSpec>
}

export type TypedExtendedFetch<ApiSpec extends AnyApiSpec> = <
  K extends keyof ApiSpec,
  Method extends HttpMethod & keyof ApiSpec[K],
>(
  request: K,
  options: {
    method?: Method
    body?: RequestBody<ApiSpec, K, Method>
    params?: PathParams<ApiSpec, K, Method>
    query?: QueryParams<ApiSpec, K, Method>
    responseType?: ResponseBody<ApiSpec, K, Method>
  } & Omit<
    FetchOptions,
    'method' | 'body' | 'params' | 'query' | 'responseType'
  >,
) => Promise<{
  data: ResponseBody<ApiSpec, K, Method>
  error: ResponseError<ApiSpec, K, Method> | undefined
}>

export function createTypedExtendedFetch<ApiSpec extends AnyApiSpec>(
  options: FetchOptions,
): TypedExtendedFetch<ApiSpec> {
  const fetchInstance = ofetch.create(options)

  return (async (...args: Parameters<typeof fetchInstance>) => {
    let data = null
    let error = null
    try {
      data = await fetchInstance(...args)
    } catch (e: any) {
      error = e.data
    }
    return { data, error }
  }) as TypedExtendedFetch<ApiSpec>
}
