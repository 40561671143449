import { useTelegram } from '@st/telegram/composables/useTelegram'

export default defineNuxtPlugin({
  name: 'telegramBackButtonWatcher',
  setup(nuxt) {
    const route = useRoute()
    const router = useRouter()
    const { isTelegramMiniApp, telegram } = useTelegram()

    function checkIsMainPage() {
      return /^\/([a-z]{2}\/)?(mobile\/?)?$/.test(route.path)
    }

    nuxt.hook('app:mounted', () => {
      watch(
        () => route.path,
        () => {
          if (!isTelegramMiniApp.value) return
          if (checkIsMainPage()) {
            telegram.BackButton.offClick(() => router.back())
            telegram.BackButton.hide()
            return
          }
          if (!telegram.BackButton.isVisible) {
            telegram.BackButton.show()
            telegram.BackButton.onClick(() => router.back())
          }
        },
        {
          immediate: true,
        },
      )
    })
  },
})
