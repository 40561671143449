<template>
  <Teleport to="body">
    <Transition name="modal">
      <div v-if="modelValue" class="backdrop" data-t="modal-backdrop">
        <div
          ref="modal"
          :data-t="dataT"
          aria-modal="true"
          class="modal"
          role="dialog"
        >
          <slot />

          <StButton
            class="close-button"
            data-t="modal-close-button"
            icon="cross-large"
            size="m"
            type="ghost"
            @click="closeModal"
          />
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    modelValue: boolean
    closeButton?: boolean
    closeOnClickOutside?: boolean
    dataT?: string
  }>(),
  {
    closeButton: true,
    closeOnClickOutside: true,
    dataT: 'st-modal',
  },
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
  (e: 'handleModalClose'): void
}>()

const modal = ref<HTMLDivElement>()

useFocusTrap(modal)

function closeModal() {
  emit('update:modelValue', false)
  emit('handleModalClose')
}

onClickOutside(modal, () => {
  if (props.closeOnClickOutside) {
    closeModal()
  }
})

function lockSwitcher(key: boolean) {
  if (typeof document !== 'undefined') {
    if (key) document.body.classList.add('no-scroll')
    else document.body.classList.remove('no-scroll')
  }
}

onMounted(() => {
  if (props.modelValue) {
    lockSwitcher(true)
  }
})

onUnmounted(() => {
  lockSwitcher(false)
})

watch(
  () => props.modelValue,
  (newValue) => {
    lockSwitcher(newValue)
  },
)
</script>

<style scoped>
.modal {
  position: relative;

  background: var(--background-base);
  border-radius: var(--border-radius-150);
  box-shadow: 0 0 80px -8px #1d1d2a;

  transition:
    opacity 0.5s,
    transform 0.5s;
}

.backdrop {
  position: fixed;
  z-index: 1000;
  inset: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgb(20 20 26 / 76%);

  transition: opacity 0.4s;
}

.modal-enter-from {
  opacity: 0;

  .modal {
    transform: translateY(-15px);
  }
}

.modal-leave-to {
  opacity: 0;

  .modal {
    transform: translateY(15px);
  }
}

.modal-leave-active {
  transition-duration: 0.3s;

  .modal {
    transition-duration: 0.3s;
  }
}

.close-button {
  position: absolute;
  top: 0;
  right: -44px;
  backdrop-filter: blur(8px);
}
</style>
