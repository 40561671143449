<template>
  <span class="st-spinner" data-t="st-spinner">
    <svg viewBox="25 25 50 50" :style="svgStyle">
      <circle cx="50" cy="50" r="20" :style="circleStyle" />
    </svg>
  </span>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

interface Props {
  /** Size of spinner */
  size?: number | string
  /** Stroke width of spinner */
  strokeWidth?: number | string
}

const props = withDefaults(defineProps<Props>(), {
  size: 18,
  strokeWidth: 4,
})

const svgStyle = computed(() => ({
  width: `${props.size}px`,
}))

const circleStyle = computed(() => ({
  strokeWidth: props.strokeWidth,
}))
</script>

<style scoped>
.st-spinner {
  line-height: 0;

  svg {
    transform-origin: center;
    width: 20px;
    stroke: currentcolor;
    animation: rotate 2s linear infinite;
  }

  circle {
    fill: none;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;

    animation: dash 1.5s ease-in-out infinite;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -125px;
  }
}
</style>
