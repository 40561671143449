import { EventBus } from '@st/utils'
import type { FetchContext } from 'ofetch'

export type ApiEventBus = EventBus<{
  request: FetchContext
  response: FetchContext
  requestError: FetchContext
  responseError: FetchContext
}>

export function useApiEventBus(): ApiEventBus {
  const nuxtApp = useNuxtApp()

  // фолбек чисто потому что интеграция накста и сторибука страдает какой-то херней
  return nuxtApp.$apiEventBus ?? new EventBus()
}
