export interface UseRouteMatchingReturn {
  exactlyEquals: (pathToTest: string, pattern: string) => boolean
  startsWith: (pathToTest: string, pattern: string) => boolean
  endsWith: (pathToTest: string, pattern: string) => boolean
}

export function useRouteMatching(): UseRouteMatchingReturn {
  const prefixPattern = `^(/([a-zA-Z]{2}))?(/(mobile))?/?`

  function exactlyEquals(pathToTest: string, pattern: string) {
    const indexPostfix = pattern === '/' ? '?' : ''
    const regexPattern = `${prefixPattern}${pattern}${indexPostfix}$`
    return new RegExp(regexPattern).test(pathToTest)
  }

  function startsWith(pathToTest: string, pattern: string) {
    const indexPostfix = pattern === '/' ? '?' : ''
    const regexPattern = `${prefixPattern}${pattern}${indexPostfix}`
    return new RegExp(regexPattern).test(pathToTest)
  }

  function endsWith(pathToTest: string, pattern: string) {
    return pathToTest.endsWith(pattern)
  }

  return {
    exactlyEquals,
    startsWith,
    endsWith,
  }
}
