import Decimal from '@st/decimal'

interface CurrencyRate {
  id: string
  rate: number
  /** Format: date-time */
  rateDate: string
  sourceCurrency: {
    id: number
    code: string
    type: 'fiat' | 'crypto'
  }
  targetCurrency: {
    id: number
    code: string
    type: 'fiat' | 'crypto'
  }
}

interface UseConvertParams {
  // Код или id валюты
  from: number | string
  to: number | string
}

export const useConverterStore = defineStore('converter', () => {
  const io = useSocket()

  const { execute, data } = useStFetch('/currency/rates/list', {
    method: 'post',
    immediate: false,
  })

  const currencyRates = ref<CurrencyRate[]>([])

  async function fetchCurrencyRates() {
    await execute()
    if (data.value) {
      currencyRates.value = data.value
    }
  }

  /**
   * Чисто оптимизационная затея
   * чтобы доставать курс за O(1)
   */
  type RatesLookupMap = Map<string | number, Map<string | number, CurrencyRate>>
  const ratesLookupMap = computed<RatesLookupMap>(() =>
    currencyRates.value.reduce<RatesLookupMap>((map, rate) => {
      if (!map.has(rate.sourceCurrency.code))
        map.set(rate.sourceCurrency.code, new Map())
      if (!map.has(rate.sourceCurrency.id))
        map.set(rate.sourceCurrency.id, new Map())

      map.get(rate.sourceCurrency.code)!.set(rate.targetCurrency.code, rate)
      map.get(rate.sourceCurrency.code)!.set(rate.targetCurrency.id, rate)

      map.get(rate.sourceCurrency.id)!.set(rate.targetCurrency.code, rate)
      map.get(rate.sourceCurrency.id)!.set(rate.targetCurrency.id, rate)

      return map
    }, new Map()),
  )

  function findRate(from: string | number, to: string | number) {
    return ratesLookupMap.value.get(from)?.get(to)
  }

  // TODO не факт что сокет так будет называться
  io.on('currencyRate', (newRates: CurrencyRate[]) => {
    newRates.forEach((newRate) => {
      const oldRate = findRate(
        newRate.sourceCurrency.id,
        newRate.targetCurrency.id,
      )

      if (oldRate) {
        Object.assign(oldRate, newRate)
      } else {
        currencyRates.value.push(newRate)
      }
    })
  })

  function convert(
    amount: string | number,
    { from, to }: UseConvertParams,
  ): string {
    const currencyRate = findRate(from, to)
    if (!currencyRate) return ''

    return new Decimal(amount).times(currencyRate.rate).toString()
  }

  return {
    currencyRates,
    fetchCurrencyRates,
    ratesLookupMap,
    convert,
  }
})
