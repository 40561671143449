import { EventBus } from '@st/utils'
import type { FetchContext } from 'ofetch'

export default defineNuxtPlugin({
  name: 'ApiEventBus',
  async setup() {
    const apiEventBus = new EventBus<{
      request: FetchContext
      response: FetchContext
      requestError: FetchContext
      responseError: FetchContext
    }>()

    return {
      provide: {
        apiEventBus,
      },
    }
  },
})
