<template>
  <div class="st-toast" :class="classes" data-t="st-toast">
    <span class="text">
      {{ props.label }}
    </span>
    <button
      class="close-button"
      data-t="st-toast-close-button"
      @click="emit('close')"
    >
      <StIcon name="cross-large" :size="16" />
    </button>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    type?: 'positive' | 'negative' | 'info'
    label: string
  }>(),
  {
    type: 'positive',
  },
)

const emit = defineEmits<{
  (e: 'close'): void
}>()

const classes = computed(() => `type-${props.type}`)
</script>

<style scoped>
.st-toast {
  display: flex;
  align-items: center;

  min-width: 200px;
  max-width: 450px;

  font: var(--desktop-text-sm-medium);

  background: var(--background-tertiary, #393946);
  border-radius: var(--border-radius-150, 12px);

  &.type-positive {
    background-color: var(--background-success);
    box-shadow:
      0 8px 24px -8px rgb(83 198 107 / 28%),
      0 4px 24px 0 rgb(17 17 24 / 80%);
  }

  &.type-negative {
    background: var(--background-error);
    box-shadow:
      0 8px 24px -8px rgb(255 66 71 / 40%),
      0 4px 24px 0 rgb(17 17 24 / 80%);
  }

  &.type-info {
    background-color: var(--background-tertiary);
    box-shadow:
      0 8px 24px -8px rgb(57 57 70 / 40%),
      0 4px 24px 0 rgb(17 17 24 / 80%);
  }
}

.text {
  flex-grow: 1;
  padding: var(--spacing-125) 0 var(--spacing-125) var(--spacing-150);
}

.close-button {
  all: unset;
  cursor: pointer;
  padding: var(--spacing-125) var(--spacing-150);
  line-height: 0;

  &:hover {
    color: var(--text-secondary);
  }

  &:active {
    color: var(--text-tertiary);
  }
}
</style>
