import { useHomeStore } from '../stores/useHomeStore'

export default defineNuxtPlugin({
  name: 'initHomeStore',
  order: 3,
  async setup() {
    const { $init } = useNuxtApp()
    const { setSbTopRules, setSbTopSportEvents } = useHomeStore()

    if ($init) {
      setSbTopRules($init.sbTopRules)
      setSbTopSportEvents($init.sbTopSportEvents)
    }
  },
})
