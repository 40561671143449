interface DateOffset {
  days?: number
  hours?: number
  minutes?: number
  seconds?: number
  milliseconds?: number
}

export function mockDateWithOffset({
  days = 0,
  hours = 0,
  minutes = 0,
  seconds = 0,
  milliseconds = 0,
}: DateOffset = {}) {
  const offsetInMS =
    milliseconds + 1000 * (seconds + 60 * (minutes + 60 * (hours + 24 * days)))

  return new Date(Date.now() + offsetInMS).toISOString()
}
