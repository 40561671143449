import { isEmptyObject } from '@st/utils'

interface CookieOptions {
  expires: string
  path: string
}

function formatCookie(
  name: string,
  value: string,
  options: CookieOptions,
): string {
  const encodedValue = encodeURIComponent(value)

  let updatedCookie = `${name}=${encodedValue};`

  Object.keys(options).forEach((option) => {
    const propValue = options[option as keyof typeof options]
    updatedCookie = `${updatedCookie}${option}=${propValue};`
  })

  return updatedCookie
}

function removeCookie(name: string) {
  const removeOptions = {
    expires: 'Thu, 01 Jan 1970',
    path: '/',
  }
  document.cookie = formatCookie(name, '', removeOptions)
}

export function setUtmCookie(cookies: Record<string, string>) {
  if (isEmptyObject(cookies)) return

  Object.keys(cookies).forEach((cookie) => {
    const utmValue = cookies[cookie as keyof typeof cookies]
    if (!utmValue) {
      removeCookie(cookie)
      return
    }

    const date = new Date()
    let expires = ''
    const utmExpiresMilliseconds = 60 * 60 * 24 * 365 * 10 * 1000

    date.setTime(date.getTime() + utmExpiresMilliseconds)

    if (date.toUTCString) {
      expires = date.toUTCString()
    }

    const options: CookieOptions = {
      expires,
      path: '/',
    }

    document.cookie = formatCookie(cookie, utmValue, options)
  })
}

export function getCookie(name: string) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop()!.split(';').shift()

  return ''
}
