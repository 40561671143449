export function usePreventInitByRoute(routes: string[], init: () => void) {
  const isInited = ref(false)
  const route = useRoute()
  watch(
    () => route.path,
    (newPath) => {
      if (!routes.some((path) => newPath.includes(path)) && !isInited.value) {
        isInited.value = true
        init()
      }
    },
    {
      immediate: true,
    },
  )

  return {
    isInited,
  }
}
