import { useUserStore } from '@st/user/stores/useUserStore'
import { useDepositBonusesStore } from '../stores/useDepositBonusesStore'
import { useFreebetsStore } from '../stores/useFreebetsStore'
import { useFreespinsStore } from '../stores/useFreespinsStore'
import { useRegularBonusesStore } from '../stores/useRegularBonusesStore'

export function useBonusesSocket() {
  const { isAuthenticated } = storeToRefs(useUserStore())
  const io = useSocket()
  const { refreshBonuses } = useRegularBonusesStore()
  const { fetchDepositBonuses } = useDepositBonusesStore()
  const { fetchFreebets } = useFreebetsStore()
  const { fetchFreespins } = useFreespinsStore()

  function refetchBonuses() {
    if (!isAuthenticated.value) return
    refreshBonuses()
    fetchDepositBonuses()
    fetchFreebets()
    fetchFreespins()
  }

  onMounted(() => {
    io.on('rollingCurrentChanged', refetchBonuses)
  })

  onBeforeUnmount(() => {
    io.off('rollingCurrentChanged', refetchBonuses)
  })
}
