import Decimal from '@st/decimal'

export const useTariffsStore = defineStore('tariffs', () => {
  const { data: tariffs, execute: getTariffs } = useStFetch(
    '/payment-tariff/get',
    {
      method: 'POST',
      immediate: false,
      watch: false,
    },
  )

  const limits = computed(() => tariffs.value?.limits)
  const fees = computed(() => tariffs.value?.fees)

  function findMinimalLimit({
    currencyId,
    operationType,
  }: {
    currencyId?: number
    operationType?: 'deposit' | 'withdrawal'
  }) {
    if (!limits.value) return undefined

    const filteredLimits = limits.value.filter(
      (limit) =>
        limit.currencyId === currencyId &&
        limit.operationType === operationType,
    )

    if (!filteredLimits.length) return undefined

    const foundLimit = filteredLimits.reduce((minLimit, limit) => {
      if (new Decimal(limit.minAmount).lessThan(minLimit.minAmount)) {
        return limit
      }

      return minLimit
    })

    if (!foundLimit) return undefined

    return foundLimit
  }

  function findLimit({
    currencyId,
    networkId,
    operationType,
  }: {
    currencyId?: number
    networkId?: number
    operationType?: 'deposit' | 'withdrawal'
  }) {
    if (!limits.value) return undefined

    return limits.value.find((limit) => {
      const isSameCurrency = !currencyId || currencyId === limit.currencyId
      const isSameOperationType =
        !operationType || operationType === limit.operationType
      const isSameNetwork = !networkId || networkId === limit.networkId

      return isSameCurrency && isSameOperationType && isSameNetwork
    })
  }

  return {
    getTariffs,
    tariffs,
    limits,
    fees,
    findMinimalLimit,
    findLimit,
  }
})
