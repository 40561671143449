import { usePreventInitByRoute } from '@st/use/composables'
import { useUserStore } from '../stores/useUserStore'

export default defineNuxtPlugin({
  name: 'autoAuth',
  parallel: true,
  setup() {
    // TODO не работет на сервере - не отправляется кука. Нужен ресерч
    const { tryToAuthenticate } = useUserStore()

    const restrictionRoutes = ['/promo/registration']
    usePreventInitByRoute(restrictionRoutes, tryToAuthenticate)
  },
})
