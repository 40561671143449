/**
 * dynamic properties
 */
const customConfigurableValues = {
  status: true,
  stats: true,
}

export default function (match = {}) {
  if (typeof match.status === 'undefined') {
    match.status = 0
  }
  if (!match.stats) {
    match.stats = {}
  }
  return new Proxy(match, {
    get(obj, prop) {
      switch (prop) {
        case 'getOwnPropertyDescriptorModified':
          return (target, prop) => {
            const descriptorValue = Reflect.getOwnPropertyDescriptor(
              target,
              prop,
            )
            const configurable = customConfigurableValues[prop]
            if (descriptorValue && !configurable) {
              descriptorValue.configurable = false
            }
            return descriptorValue
          }
        default:
          return obj[prop]
      }
    },
  })
}
