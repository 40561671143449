// @ts-expect-error bincorm is not typed
import bincorm from 'bincorm'
import type { ActualLoader, MarketStoreInstance } from '../types'
import type { BinaryDecodedActual } from '../types'
import { ofetch } from 'ofetch'
import { buildMenuFromDecodedActual } from '../helpers/buildMenuFromDecodedActual'

export interface CormacheHomePageLoaderOptions {
  platform: 'mobile' | 'desktop'
  cacheUrl: string
}

export class CormacheHomePageLoader implements ActualLoader {
  #cormacheVersion = 3
  #marketStore: MarketStoreInstance
  #options: CormacheHomePageLoaderOptions
  public name = 'CormacheHomePageLoader'

  constructor(
    marketsStore: MarketStoreInstance,
    options: CormacheHomePageLoaderOptions,
  ) {
    this.#marketStore = marketsStore
    this.#options = options
  }

  async getActual() {
    const decodedActual = await this.#fetchHomePageActual()
    const menu = buildMenuFromDecodedActual(decodedActual)

    await this.#marketStore.init(decodedActual.sportEvents, {
      menu,
      withCacheAndMessages: false,
    })
    this.#marketStore.systemStates.shortActualLoaded = true

    const { sportEvents } = await this.#fetchFullActual()
    this.#marketStore.updateActual(sportEvents)
    this.#marketStore.systemStates.fullActualLoaded = true

    this.#marketStore.readMessages()
    await this.#marketStore.initCache()
  }

  async #fetchFullActual(): Promise<BinaryDecodedActual> {
    const { platform, cacheUrl } = this.#options
    const { lang } = this.#marketStore

    const response = await ofetch(`${cacheUrl}/sb-actual`, {
      credentials: 'omit',
      responseType: 'arrayBuffer',
      query: {
        version: this.#cormacheVersion,
        platform,
        language: lang,
      },
    })

    return bincorm.decodeSbActual({
      binaryActual: new Uint8Array(response),
    })
  }

  async #fetchHomePageActual(): Promise<BinaryDecodedActual> {
    const { cacheUrl } = this.#options
    const { lang } = this.#marketStore

    const response = await ofetch(`${cacheUrl}/sb-actual/main-page`, {
      credentials: 'omit',
      responseType: 'arrayBuffer',
      query: {
        version: this.#cormacheVersion,
        language: lang,
      },
    })

    return bincorm.decodeSbActual({
      binaryActual: new Uint8Array(response),
    })
  }
}
