import { useLocalStorage } from '@st/use/composables'

declare global {
  // eslint-disable-next-line -- why var see in https://stackoverflow.com/questions/59459312/using-globalthis-in-typescript
  var dataLayer: unknown[]
}

interface CookiesStorage {
  functional: boolean
  analytic: boolean
  configured: boolean
}

export function useInsertGTM() {
  const config = useRuntimeConfig()
  const gtm = config.public.gtmId
  const cookiesStorage = useLocalStorage<CookiesStorage>('cookiesStorage', {
    functional: true,
    analytic: true,
    configured: false,
  })

  function insertGtm() {
    if (!gtm) {
      console.error('NUXT_PUBLIC_GTM_ID key is missing!')
      return
    }
    if (!cookiesStorage.value.analytic) {
      console.info('GTM not inserted, user analytic disabled')
      return
    }

    globalThis.dataLayer = window.dataLayer || []
    globalThis.dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    })

    const script = document.createElement('script')
    script.async = true
    script.src = `https://www.googletagmanager.com/gtm.js?id=${gtm}`
    document.head.appendChild(script)
  }

  return {
    insertGtm,
  }
}
