import { unrefObject } from '@st/use/composables'

export type Style = 'decimal' | 'currency' | 'percent' | 'unit'
export type CurrencyDisplay = 'symbol' | 'code' | 'narrowSymbol' | 'name'
export type TrailingZeroDisplay = 'auto' | 'stripIfInteger'
export type SignDisplay = 'auto' | 'never' | 'always' | 'exceptZero'
export type Notation = 'compact' | 'standard' | 'scientific' | 'engineering'

export interface UseNumberFormatOptions {
  locale?: MaybeRefOrGetter<string>
  currency?: MaybeRefOrGetter<string>
  style?: MaybeRefOrGetter<Style>
  currencyDisplay?: MaybeRefOrGetter<CurrencyDisplay>
  maximumFractionDigits?: MaybeRefOrGetter<number>
  minimumFractionDigits?: MaybeRefOrGetter<number>
  maximumSignificantDigits?: MaybeRefOrGetter<number>
  minimumSignificantDigits?: MaybeRefOrGetter<number>
  trailingZeroDisplay?: MaybeRefOrGetter<TrailingZeroDisplay>
  signDisplay?: MaybeRefOrGetter<SignDisplay>
  notation?: MaybeRefOrGetter<Notation>
}

export interface UseNumberFormatReturn {
  format: (amount: number | string, currency?: string) => string
}

/**
 * Хук для форматирования чисел.
 * За основу взят встроенный функционал `Intl.NumberFormat`.
 * Формат зависит от локали i18n.
 * Каждый параметр может быть передан как Ref для реактивного обновления.
 *
 * @param locale Язык. По умолчанию берется из i18n
 * @param maximumFractionDigits  Максимальное количество знаков после запятой
 * @param minimumFractionDigits  Минимальное количество знаков после запятой
 * @param currency Код валюты или 'auto'. Если auto - берет валюту приложения. По умолчанию undefined
 * @param style Стиль форматирования. Может быть 'decimal' | 'currency' | 'percent' | 'unit'
 * @param trailingZeroDisplay Стратегия отображения нулей в конце целых чисел. Возможные значения: 'auto'(По умолчанию) | 'stripIfInteger'
 * @param notation Форматирование для отображения чисел (используется для компактного вида чисел 'compact')
 * @example
 * const { format } = useNumberFormatter({ currency: 'USD' })
 * format(1000) // 1 000 $
 */
export default function useNumberFormatter(
  options: UseNumberFormatOptions = {},
): UseNumberFormatReturn {
  const { locale = useI18n().locale } = options

  const unrefedOptions = computed(() => unrefObject(options))

  const formatter = computed(
    () => new Intl.NumberFormat(toValue(locale), unrefedOptions.value),
  )

  const integerFormatter = computed(
    () =>
      new Intl.NumberFormat(toValue(locale), {
        ...unrefedOptions.value,
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }),
  )

  function format(amount: number | string): string {
    const needsIntegerFormatter =
      unrefedOptions.value.trailingZeroDisplay === 'stripIfInteger' &&
      Number.isInteger(amount)

    return needsIntegerFormatter
      ? integerFormatter.value.format(Number(amount))
      : formatter.value.format(Number(amount))
  }

  return {
    format,
  }
}
