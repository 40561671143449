/**
 * Creates a copy of an object without specified properties. The opposite of pick.
 * Analog of https://docs-lodash.com/v4/omit/
 *
 * @param object the source object
 * @param keys properties to omit
 *
 * @example
 * const object = { 'a': 1, 'b': 'foo', 'c': 'bar' };
 * omit(object, ['a', 'c']) // { b: 'foo' }
 */
export function omit<T, K extends keyof T>(source: T, keys: K[]): Omit<T, K> {
  const result: any = {}
  const keysToOmit = new Set<any>(keys)

  for (const key in source) {
    if (!keysToOmit.has(key)) {
      result[key] = source[key]
    }
  }
  return result
}
