export function findAll(method, params, page = 1, arr = [], client) {
  params = { ...params, page }

  return new Promise((resolve, reject) => {
    client[method](params).then((resp) => {
      arr = [...arr, ...resp.rows]

      if (page >= resp.totalPages) {
        resolve(arr)
      } else {
        page += 1

        resolve(findAll(method, params, page, arr, client))
      }
    })
  })
}
