import { delay } from '@st/utils'

export interface User {
  externalId: string
  createdAt: string
  email: string
  nickname: string
  kycLevel: number
  avatarUrl: string
  intercomHash: string
  referralCode: string
  customerSegment: number
}

export interface UserSettings {
  clearCouponAfterBet: boolean
  acceptChangeBetRate: 'up' | 'any' | 'never'
  acceptChangeBetCashOutAmount: 'up' | 'any' | 'never'
  language: string
  preferredAccountType: 'real' | 'bonus' | 'freebet'
  preferredCurrencyCode: string
  preferredCurrencyCodeForCasino: string
  showNicknameOnMainPage: boolean
  agreedGetPromoNews: boolean
  showZeroAccounts: boolean
}

interface AuthProvider {
  provider: 'email' | 'google' | 'telegram'
  externalId: string | null
}

export const useUserStore = defineStore('user', () => {
  const { setIntercomUserData, closeChatSession } = useIntercom()
  const stFetch = useRawStFetch()

  const app = useNuxtApp()
  const user = ref<User | null>(null)
  const userSettings = ref<UserSettings | null>(null)
  const isAuthenticated = computed(() => !!user.value)
  const isReady = ref<boolean>(false)
  const isManualLogin = ref(false)
  const providersList = ref<AuthProvider[]>([])

  async function fetchUserSettings() {
    userSettings.value = await stFetch('/user-settings/get', {
      method: 'post',
    })
  }

  async function updateUserSettings(
    data: {
      name: keyof UserSettings
      value: UserSettings[keyof UserSettings]
    }[],
  ) {
    await stFetch('/user-settings/set', { method: 'post', body: data })
    await fetchUserSettings()
  }

  async function socketReconnect() {
    if (!app.$io) return

    app.$io.disconnect()
    await delay(150)
    app.$io.connect()
  }

  function updateUserLanguage() {
    if (
      userSettings.value?.language &&
      app.$i18n.locale !== userSettings.value.language
    ) {
      updateUserSettings([{ name: 'language', value: app.$i18n.locale.value }])
    }
  }

  async function getProvidersList() {
    providersList.value = await stFetch('/user/auth/provider/list', {
      method: 'post',
    })
  }
  const hasEmail2FA = computed(
    () => providersList.value?.some((item) => item.provider === 'email'),
  )

  const hasGoogle2FA = computed(
    () => providersList.value?.some((item) => item.provider === 'google'),
  )

  const hasTelegram2FA = computed(
    () => providersList.value?.some((item) => item.provider === 'telegram'),
  )

  const has2FA = computed(
    () => hasEmail2FA.value || hasGoogle2FA.value || hasTelegram2FA.value,
  )

  const { sendFingerPrintData } = useIpqs()
  /**
   * Обрабатывает успешный логин пользователя.
   * Это касается успешного прохождения реги/авторизации
   * и автоматического логина при повторном заходе на сайт
   */
  async function handleLogin(userData: User) {
    user.value = userData
    isReady.value = true
    await fetchUserSettings()
    await socketReconnect()
    setIntercomUserData(userData)
    sendFingerPrintData(userData.externalId)
    updateUserLanguage()
    await getProvidersList()
  }

  /**
   * При повторном заходе на сайт у юзера уже может стоять кука авторизации.
   * Но мы никак не можем проверить ее наличие - она http-only.
   * Поэтому этот метод пробует запросить данные юзера.
   * Если запрос прошел - значит юзер авторизован, иначе нет.
   */
  async function tryToAuthenticate() {
    try {
      const response = await stFetch('/user', { method: 'post' })
      await handleLogin(response)
    } catch (error) {
      // ignore
    } finally {
      isReady.value = true
    }
  }

  async function refreshUserData() {
    try {
      user.value = await stFetch('/user', { method: 'post' })
    } catch (error) {
      console.error('Failed to refresh user data:', error)
    }
  }

  function resetUserData() {
    user.value = null
    userSettings.value = null
    isManualLogin.value = false
    closeChatSession()
  }

  const router = useRouter()
  async function handleFullLogout() {
    resetUserData()
    await socketReconnect()
    router.push('/')
  }
  async function logout() {
    await stFetch('/auth/logout', { method: 'post' })
    handleFullLogout()
  }

  return {
    tryToAuthenticate,
    user,
    isReady,
    isManualLogin,
    isAuthenticated,
    handleLogin,
    userSettings,
    updateUserSettings,
    logout,
    resetUserData,
    fetchUserSettings,
    refreshUserData,
    handleFullLogout,
    hasEmail2FA,
    hasGoogle2FA,
    hasTelegram2FA,
    has2FA,
  }
})
