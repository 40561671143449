import type { IconName } from '@st/ui/components/StIcon/types'
import type { CurrencyCode } from '../interfaces'

const networkIconsNameMap: Record<string, string> = {
  ethereum: 'c-crypto-icon-erc',
  bsc: 'c-crypto-icon-bep',
  bitcoin: 'c-crypto-icon-bitcoin',
  litecoin: 'c-crypto-icon-litecoin',
  tron: 'c-crypto-icon-trc',
  ton: 'c-crypto-icon-ton-network',
  polygon: 'c-crypto-icon-matic',
}

const currenciesIconsNameMap: Record<CurrencyCode, IconName> = {
  btc: 'c-crypto-icon-btc',
  bnb: 'c-crypto-icon-bnb',
  usdt: 'c-crypto-icon-usdt',
  usdc: 'c-crypto-icon-usdc',
  eth: 'c-crypto-icon-eth',
  ltc: 'c-crypto-icon-ltc',
  trx: 'c-crypto-icon-trx',
  ton: 'c-crypto-icon-ton',
  matic: 'c-crypto-icon-polygon',
}

export function getNetworkIconName(code: string) {
  return networkIconsNameMap[code as CurrencyCode] ?? 'default-icon'
}

export function getCurrencyIconName(code: string) {
  return currenciesIconsNameMap[code as CurrencyCode] ?? 'default-icon'
}

export function truncateAddress(address: string | null) {
  if (!address) return ''

  if (address.length <= 14) {
    return address
  }

  const firstTwoSymbols = address.slice(0, 2)

  const leftLength = firstTwoSymbols === '0x' ? 8 : 6
  const rightLength = 6

  const leftSide = address.slice(0, leftLength)
  const rightSide = address.slice(-1 * rightLength)

  return `${leftSide}...${rightSide}`
}
