<template>
  <Html :lang="head?.htmlAttrs?.lang ?? ''" :dir="head?.htmlAttrs?.dir ?? ''">
    <Head>
      <Link
        v-for="link in head.link"
        :id="link.id"
        :key="link.id"
        :rel="link.rel"
        :href="link.href"
        :hreflang="link.hreflang"
      />
      <Meta
        v-for="meta in head.meta"
        :id="meta.id"
        :key="meta.id"
        :property="meta.property"
        :content="meta.content"
      />
    </Head>
    <Body>
      <StModalManager :modals="modalConfig" />
      <StToastManager :position="toastsPosition" />
      <NuxtLayout>
        <NuxtPage @click="handleClick" />
        <NotificationsManager />
      </NuxtLayout>
      <Cookies />
    </Body>
  </Html>
</template>

<script lang="ts" setup>
import { useUserStore } from '@st/user/stores/useUserStore'
import { useActiveBetsStore } from '@st/coupon/stores/useActiveBetsStore'
import { useDepositBonusesStore } from '@st/bonuses/stores/useDepositBonusesStore'
import { useGlobalOutcomeButtonClickHandler } from '@st/coupon/composables/useGlobalOutcomeButtonClickHandler'
import { useBonusesSocket } from '@st/bonuses/composables/useBonusesSocket'
import { useModalConfig } from './modal.config'

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
})

const { isMobile } = usePlatform()
const toastsPosition = computed(() => (isMobile.value ? 'top' : 'bottom'))
const modalConfig = useModalConfig()

/*
  по какой-то причине после fix extends resolutions
  сторы инитятся до всех плагинов
  и внутри него нет io
*/
const io = useSocket()
const { handleFullLogout } = useUserStore()
const { activeBetListener } = useActiveBetsStore()
io.on('fullLogout', handleFullLogout)
io.on('betStatus', activeBetListener)

const { isAuthenticated, isManualLogin } = storeToRefs(useUserStore())

const { handleDepositModalShow } = useDepositBonusesStore()

watch(
  () => isAuthenticated.value,
  (isAuth) => {
    if (isAuth && isManualLogin.value) {
      handleDepositModalShow()
    }
  },
)

const { handleClick } = useGlobalOutcomeButtonClickHandler()

onMounted(() => {
  useBonusesSocket()
})
</script>

<style>
@import url('@st/fonts/SFProDisplay/index.css');

:root {
  scrollbar-gutter: stable;
  font-family: var(--sf-pro-display);
  background-color: var(--background-base);
}

/* hide scroll with modal */
.no-scroll {
  overflow: hidden;
  height: 100%;
}

/* hide recaptcha badge */
.grecaptcha-badge {
  visibility: hidden;
}
</style>
