import { http } from 'msw'

import type {
  AnyApiSpec,
  HttpMethod,
  PathParams,
  PathsForMethod,
  RequestBody,
  ResponseBody,
} from './typeHelpers'

/** MSW handler options. */
export type RequestHandlerOptions = Required<Parameters<typeof http.all>[2]>

type ResponseResolverType<
  ApiSpec extends AnyApiSpec,
  Path extends keyof ApiSpec,
  Method extends HttpMethod & keyof ApiSpec[Path],
> = Parameters<
  typeof http.all<
    PathParams<ApiSpec, Path, Method>,
    RequestBody<ApiSpec, Path, Method>,
    ResponseBody<ApiSpec, Path, Method>
  >
>[1]

/** MSW response resolver function that is made type-safe through an api spec. */
export type ResponseResolver<
  ApiSpec extends AnyApiSpec,
  Path extends keyof ApiSpec,
  Method extends HttpMethod & keyof ApiSpec[Path],
> = ResponseResolverType<ApiSpec, Path, Method>

/** MSW http handler factory with type inference for provided api paths. */
export type HttpHandlerFactory<
  ApiSpec extends AnyApiSpec,
  Method extends HttpMethod,
> = <Path extends PathsForMethod<ApiSpec, Method>>(
  path: Path,
  resolver: Method extends keyof ApiSpec[Path]
    ? ResponseResolver<ApiSpec, Path, Method>
    : never,
  options?: RequestHandlerOptions,
) => ReturnType<typeof http.all>

/**
 * Converts a OpenAPI path fragment convention to the colon convention that is
 * commonly used in Node.js and also MSW.
 *
 * @example /users/{id} --> /users/:id
 */
export function convertToColonPath(path: string, baseUrl?: string): string {
  const resolvedPath = path.replace(/{/g, ':').replace(/}/g, '')
  if (!baseUrl) return resolvedPath

  return baseUrl + resolvedPath
}

/** Collection of enhanced HTTP handler factories for each available HTTP Method. */
export type OpenApiHttpHandlers<ApiSpec extends AnyApiSpec> = {
  [Method in HttpMethod]: HttpHandlerFactory<ApiSpec, Method>
} & { untyped: typeof http }

export interface HttpOptions {
  /** Optional baseUrl that is prepended to the `path` of each HTTP handler. */
  baseUrl?: string
}

function createHttpWrapper<
  ApiSpec extends AnyApiSpec,
  Method extends HttpMethod,
>(
  method: Method,
  httpOptions?: HttpOptions,
): HttpHandlerFactory<ApiSpec, Method> {
  return (path, resolver, options) => {
    const mswPath = convertToColonPath(path as string, httpOptions?.baseUrl)
    return http[method](mswPath, resolver as any, options)
  }
}

/**
 * Создает типизированную обертку над MSW http
 * на основе сгенерированной openapi-typescript схемы
 *
 * @example
 * import type { paths } from "./your-openapi-schema";
 *
 * const http = createTypedHttp<paths>();
 *
 * // Урлы, параметры запросов и ответов - все типизировано
 * const getHandler = http.get("/resource/{id}", ({ params }) => {
 *   const id = params.id
 *   return HttpResponse.json({ id, other: "..." })
 * })
 */
export function createTypedHttp<ApiSpec extends AnyApiSpec>(
  options?: HttpOptions,
): OpenApiHttpHandlers<ApiSpec> {
  return {
    get: createHttpWrapper<ApiSpec, 'get'>('get', options),
    put: createHttpWrapper<ApiSpec, 'put'>('put', options),
    post: createHttpWrapper<ApiSpec, 'post'>('post', options),
    delete: createHttpWrapper<ApiSpec, 'delete'>('delete', options),
    options: createHttpWrapper<ApiSpec, 'options'>('options', options),
    head: createHttpWrapper<ApiSpec, 'head'>('head', options),
    patch: createHttpWrapper<ApiSpec, 'patch'>('patch', options),
    untyped: http,
  }
}
