/**
 * dynamic properties
 */
const customConfigurableValues = {
  weight: true,
  state: true,
  link: true,
}

export default function (obj) {
  // const sport = Object.assign({}, obj)
  obj.link = ''

  return new Proxy(obj, {
    get(arr, prop) {
      switch (prop) {
        case 'getOwnPropertyDescriptorModified':
          return (target, prop) => {
            const descriptorValue = Reflect.getOwnPropertyDescriptor(
              target,
              prop,
            )
            const configurable = customConfigurableValues[prop]
            if (descriptorValue && !configurable) {
              descriptorValue.configurable = false
            }
            return descriptorValue
          }
        default:
          return arr[prop]
      }
    },
  })
}
