/**
 * getOwnPropertyDescriptor polyfill
 */
import Sport from './Sport'
import Category from './Category'
import Market from './Market'
import SportEvent from './SportEvent'
import IndexedActual from './IndexedActual'
import IndexedArray from './IndexedArray'

const originalDescriptor = Object.getOwnPropertyDescriptor
const modifiedDescriptor = function (...args) {
  const [target, prop] = args
  if (
    !(
      target.getOwnPropertyDescriptorModified &&
      typeof target.getOwnPropertyDescriptorModified === 'function'
    )
  )
    return originalDescriptor(...args)
  const descriptors = target.getOwnPropertyDescriptorModified(target, prop)
  if (descriptors) {
    return descriptors
  }
  return originalDescriptor(...args)
}
Object.getOwnPropertyDescriptor = modifiedDescriptor

export { Sport, Category, Market, SportEvent, IndexedActual, IndexedArray }
