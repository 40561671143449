import { delay } from '@st/utils'
import type { Toast } from '../components/StToast/types'

export interface OpenToastParams {
  label: string
  type?: Toast['type']
}

export function useToast() {
  const toasts = useState<Toast[]>('toasts', () => [])

  async function open(params: OpenToastParams) {
    toasts.value.push({
      label: params.label,
      type: params.type ?? 'info',
      key: `${Date.now()}-${toasts.value.length}`,
    })
    await delay(4000)
    toasts.value.shift()
  }

  return { open }
}
