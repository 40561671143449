import { useUserStore } from '@st/user/stores/useUserStore'
import { useVerificationStore } from '../stores/useVerificationStore'

export default defineNuxtPlugin({
  name: 'verificationStoreInitPlugin',
  async setup() {
    const route = useRoute()
    const router = useRouter()
    const { fetchUserVerification } = useVerificationStore()
    const { userVerification } = storeToRefs(useVerificationStore())
    const { isAuthenticated } = storeToRefs(useUserStore())

    async function changeRoute(path: 'verification' | 'biometric') {
      await router.replace({ query: { modal: path } })
    }

    async function showVerificationModals() {
      await fetchUserVerification()

      if (!userVerification.value) return

      const { status, required, type } = userVerification.value

      if (status === 'userWait' && required) {
        if (type === 'actionLiveness') {
          await changeRoute('biometric')
        } else {
          await changeRoute('verification')
        }
      }
    }

    const routesToShowModals = [
      '?modal=payments&tab=withdrawal',
      '/personal/kyc',
    ]

    onNuxtReady(() => {
      /**
       * Если юзер залогинился, то показываем модалку
       */
      watch(
        isAuthenticated,
        async (newValue) => {
          if (newValue) await showVerificationModals()
        },
        { immediate: true },
      )
      /**
       * Отслеживаем роуты на которых надо показывать модалки и исключаем
       * цикличный вывод модалок если старый путь = роутам из массива
       */
      watch(
        () => route.fullPath,
        (newValue, oldValue) => {
          if (
            routesToShowModals.some((path) => newValue.includes(path)) &&
            routesToShowModals.every((path) => !oldValue?.includes(path))
          ) {
            showVerificationModals()
          }
        },
      )
    })
  },
})
