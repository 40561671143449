import type { Startup } from '../types'

declare global {
  // eslint-disable-next-line -- why var see in https://stackoverflow.com/questions/59459312/using-globalthis-in-typescript
  var Startup: Startup
}

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()
  const apiKey = config.public.ipqsKey

  if (!apiKey) {
    console.error('NUXT_PUBLIC_IPQS_KEY is missing!')
    return
  }

  /**
   * "костыльный" метод загрузки скрипта, из-за некорректного load колбэка
   */
  if (typeof globalThis.Startup === 'undefined') {
    setTimeout(() => {
      const scriptPath = `https://www.ipqscdn.com/api/${apiKey}/learn.js`

      const ipqsScript = document.createElement('script')
      ipqsScript.setAttribute('src', scriptPath)
      ipqsScript.setAttribute('crossorigin', 'anonymous')
      document.head.appendChild(ipqsScript)

      ipqsScript.onload = () => {
        globalThis.Startup?.Pause()
      }
      ipqsScript.onerror = () => {
        console.error('Failed to load Ipqs')
      }
    }, 200)
  }
})
