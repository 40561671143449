declare global {
  // eslint-disable-next-line -- why var see in https://stackoverflow.com/questions/59459312/using-globalthis-in-typescript
  var Intercom: Intercom
}

type IntercomEvent = 'boot' | 'update' | 'shutdown'
interface IntercomeEventData {
  api_base?: 'https://api-iam.intercom.io'
  custom_launcher_selector?: string /* селектор для кастомных кнопок, открывающих чат */
  app_id?: string
  name?: string /* Никнейм клиента из параметра nickname */
  user_id?: string /* externalID клиента */
  created_at?: string /* дата регистрации клиента из параметра createdAt */
  user_hash?: string /* Хэш, сгеренированный на бэке intercomHash */
  language_override?: string /* Язык виджета */
  customer_segment?: number /* Сегмент клиента */
}
type Intercom = (event: IntercomEvent, data?: IntercomeEventData) => void
interface User {
  externalId: string
  createdAt: string
  email: string
  nickname: string
  kycLevel: number
  avatarUrl: string
  intercomHash: string
  customerSegment: number
}

/**
 * хук для работы с онлайн чатом Intercom
 * работает только на клиенте через window.Intercom
 */
export function useIntercom() {
  const config = useRuntimeConfig()
  const apiKey = config.public.intercomKey as string
  const isChatInitedWithUser = ref(false)
  const app = useNuxtApp()

  const userData = ref<User | null>(null)

  async function initDefaultChat() {
    if (isChatInitedWithUser.value) return

    try {
      window?.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: apiKey,
        custom_launcher_selector: '#intercomButton',
        language_override: app.$i18n?.locale.value ?? 'en',
      })
    } catch (error) {
      console.error('Intercom boot error', error)
    }
  }

  function setIntercomUserData(data: User) {
    userData.value = data
  }
  async function initUserChat({
    externalId,
    createdAt,
    intercomHash,
    nickname,
    customerSegment,
  }: User) {
    isChatInitedWithUser.value = true
    try {
      window?.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        custom_launcher_selector: '#intercomButton',
        app_id: apiKey,
        name: nickname,
        user_id: externalId,
        created_at: createdAt,
        user_hash: intercomHash,
        language_override: app.$i18n?.locale.value ?? 'en',
        customer_segment: customerSegment,
      })
    } catch (error) {
      console.error('Intercom boot user error', error)
    }
  }

  function updateChatSession(data?: User) {
    const { externalId, createdAt, intercomHash, nickname, customerSegment } =
      data ?? {}

    try {
      if (intercomHash) {
        window?.Intercom('update', {
          api_base: 'https://api-iam.intercom.io',
          custom_launcher_selector: '#intercomButton',
          app_id: apiKey,
          name: nickname,
          user_id: externalId,
          created_at: createdAt,
          user_hash: intercomHash,
          language_override: app.$i18n?.locale.value ?? 'en',
          customer_segment: customerSegment,
        })
      } else window?.Intercom('update')
    } catch (error) {
      console.error('Intercom update error', error)
    }
  }

  function closeChatSession() {
    try {
      window?.Intercom('shutdown')
      isChatInitedWithUser.value = false
      userData.value = null
      updateChatSession()
      initDefaultChat()
    } catch (error) {
      console.error('Intercom shutdown error', error)
    }
  }

  const apiEventBus = useApiEventBus()
  const isForbiddenByCountry = ref(false)
  apiEventBus.on('responseError', (context) => {
    // eslint-disable-next-line no-underscore-dangle
    const response = context.response?._data ?? {}

    if (response.error !== 'FORBIDDEN_COUNTRY') return

    isForbiddenByCountry.value = true
    closeChatSession()
  })

  function initChat() {
    if (userData.value && !isForbiddenByCountry.value) {
      initUserChat(userData.value)
    } else initDefaultChat()
  }

  watchEffect(() => {
    if (userData.value && app.$intercomLoaded?.value) {
      window?.Intercom('shutdown') // завершаем дефолтную сессию
      initChat()
      updateChatSession(userData.value)
    }
  })

  return {
    initDefaultChat,
    initUserChat,
    setIntercomUserData,
    updateChatSession,
    closeChatSession,
    initChat,
  }
}
