import type { RouteLocation } from 'vue-router'
import { usePrefixRoute } from '../composables/usePrefixRoute'

function loadDayjsLocale(locale: string) {
  switch (locale) {
    case 'ru':
      return import('dayjs/locale/ru.js')
    case 'en':
      return import('dayjs/locale/en.js')
    case 'es':
      return import('dayjs/locale/es.js')
    case 'pt':
      return import('dayjs/locale/pt.js')
    default:
      return import('dayjs/locale/en.js')
  }
}

export default defineNuxtPlugin({
  name: 'i18n-router-push',
  async setup() {
    const router = useRouter()
    const { $i18n } = useNuxtApp()

    const prefixRoute = usePrefixRoute()

    loadDayjsLocale($i18n.locale.value)

    const previousRoute = ref<RouteLocation>()

    const originalPush = router.push
    router.push = (...[to]: Parameters<typeof originalPush>) => {
      previousRoute.value = router.currentRoute.value
      return originalPush(prefixRoute(to))
    }

    const originalReplace = router.replace
    router.replace = (...[to]: Parameters<typeof originalReplace>) =>
      originalReplace(prefixRoute(to))

    return {
      provide: {
        previousRoute,
      },
    }
  },
})
