import type { TypedUseFetch } from '../nuxtOpenapiTypeHelpers'
import type { paths } from '../paths'
import { useApiConfig } from './useApiConfig'

export const useStFetch: TypedUseFetch<paths> = (url, options) => {
  const { createApiConfig } = useApiConfig()
  const config = createApiConfig(options as any)

  return useFetch(url, {
    key: Math.random().toString() + url,
    ...(config as any),
  })
}
