export const _createMinMaxBy = (methodName: 'min' | 'max') =>
  function <T, K>(
    array: T[],
    iteratee: (element: T, index: number, array: T[]) => K,
  ) {
    let bestElement: T | undefined
    let bestWeight: K

    array.forEach((element, index) => {
      const weight = iteratee(element, index, array)

      if (
        !bestElement ||
        (methodName === 'max' && weight > bestWeight) ||
        (methodName === 'min' && weight < bestWeight)
      ) {
        bestElement = element
        bestWeight = weight
      }
    })

    return bestElement
  }
