import { useUserStore } from '@st/user/stores/useUserStore'
import type { BetStatus } from '../types'

export const useActiveBetsStore = defineStore('activeBets', () => {
  const { isAuthenticated } = storeToRefs(useUserStore())

  const { data, execute: fetchActiveBetsCount } = useStFetch('/bet/find', {
    method: 'post',
    body: {
      pagination: {
        perPage: 1,
        page: 1,
        orderBy: [
          {
            sortOrder: 'ASC',
            fieldName: 'id',
          },
        ],
      },
      params: {
        status: ['accepted', 'paused'],
      },
    },
    immediate: false,
    watch: false,
  })
  const betCounter = ref(0)

  async function updateActiveBetsCounters() {
    if (isAuthenticated.value) {
      await fetchActiveBetsCount()
      betCounter.value = data.value?.paging.total ?? 0
    } else betCounter.value = 0
  }
  function activeBetListener(payload: { betId: number; betStatus: BetStatus }) {
    if (['accepted', 'paused', 'processed'].includes(payload.betStatus)) {
      updateActiveBetsCounters()
    }
  }

  const isReady = ref(false)
  /**
   * запрашивает каунтеры при наличии юзера
   * сбрасывает в 0 при отсутствии
   */
  watch(
    () => isAuthenticated.value,
    async (isAuth) => {
      await updateActiveBetsCounters()
      if (isAuth) {
        isReady.value = true
      }
    },
    { immediate: true },
  )

  return {
    betCounter,
    isReady,
    activeBetListener,
  }
})
