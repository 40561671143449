import type { TMarket, TOutcome, TSportEvent } from 'markets-store'
import { setBy } from '@st/utils'
import { addNamesToMarket } from 'markets-store/helpers/addNamesToMarket'
import type { MarketStoreInstance } from 'markets-store/factory/types'
import { MARKET_STATES } from 'markets-store/constants'

export interface CouponOutcome {
  uniqId: string
  eventId: number
  marketId: number
  marketSpecifiers: string
  outcomeId: number
}

export interface ExtendedCouponOutcome extends CouponOutcome {
  event: TSportEvent
  market: TMarket
  outcome: TOutcome
  isActive: boolean
}

export const useCouponStore = defineStore('coupon', () => {
  /** Чтобы не тянуть прямую зависимость от маркетстора, его нужно явно засетить */
  const marketsStore = ref<MarketStoreInstance>()
  function setMarketsStore(value: MarketStoreInstance) {
    marketsStore.value = value
  }

  const isLoading = ref(false)

  const outcomes = ref<CouponOutcome[]>([])
  const outcomesUniqIds = computed(() =>
    setBy(outcomes.value, (outcome) => outcome.uniqId),
  )

  function hasOutcome(uniqId: string) {
    return outcomesUniqIds.value.has(uniqId)
  }

  function removeOutcome(uniqId: string) {
    outcomes.value = outcomes.value.filter(
      (otherOutcome) => otherOutcome.uniqId !== uniqId,
    )
  }

  function addOutcome(outcome: CouponOutcome) {
    outcomes.value.push(outcome)
  }

  function clear() {
    outcomes.value = []
  }

  const extendedOutcomes = computed<ExtendedCouponOutcome[]>(() => {
    const MS = marketsStore.value
    if (!MS || !MS.systemStates.shortActualLoaded) return []

    return outcomes.value.map((couponOutcome) => {
      const event = MS.sportEvents.getById(couponOutcome.eventId) as TSportEvent
      const markets = MS.getMarketsByEventId(couponOutcome.eventId)

      const market = markets.find(
        ({ id, specifiersString }) =>
          id === couponOutcome.marketId &&
          specifiersString === couponOutcome.marketSpecifiers,
      ) as TMarket
      const outcome = market?.outcomes?.find(
        (item) => item.id === couponOutcome.outcomeId,
      ) as TOutcome

      const isActive =
        !!event &&
        !!market &&
        !!outcome &&
        [MARKET_STATES.ACTIVE, MARKET_STATES.RETURNED].includes(market.state) &&
        outcome.active

      return {
        ...couponOutcome,
        event,
        market,
        outcome,
        isActive,
      }
    })
  })

  const outcomeCount = computed(() => extendedOutcomes.value.length)

  /**
   * Изначально у маркетов и ауткамов пустые имена (вероятно в целях экономии памяти)
   * Есть очень поехваший механизм их получения.
   * Если есть желание вникнуть - смотри в addNamesToMarket
   */
  watchEffect(() => {
    const MS = marketsStore.value
    if (!MS || !MS.systemStates.shortActualLoaded) return

    extendedOutcomes.value.forEach(({ outcome, market }) => {
      if (!outcome.name || !market.name) {
        addNamesToMarket({
          marketsStore: MS,
          market,
        })
      }
    })
  })

  return {
    setMarketsStore,
    addOutcome,
    removeOutcome,
    hasOutcome,
    clear,
    outcomeCount,
    isLoading,
    outcomes: extendedOutcomes,
  }
})
