import { useUserStore } from '@st/user/stores/useUserStore'
import { useTelegram } from '@st/telegram/composables/useTelegram'

export default defineNuxtPlugin({
  name: 'showTelegramAuth',
  setup(nuxt) {
    const { isAuthenticated } = storeToRefs(useUserStore())
    const { isTelegramMiniApp } = useTelegram()

    const router = useRouter()

    nuxt.hook('app:mounted', async () => {
      if (!isAuthenticated.value && isTelegramMiniApp?.value) {
        router.replace({
          query: {
            modal: 'register',
          },
        })
      }
    })
  },
})
