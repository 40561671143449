import type { RouteLocationRaw } from 'vue-router'

export interface PrefixRouteOptions {
  locale?: string
  platform?: 'mobile' | 'desktop'
}

/**
 * Этот хук решает проблему с необходимостью
 * префиксить ссылки для мобилки и других языков
 * Функция prefixRoute автоматически подставляет нужные префиксы.
 * Это используется внутри router.push и NuxtI18nLink
 *
 * @example
 * prefixRoute('/casino')
 * en desktop -> '/casino'
 * ru desktop -> '/ru/casino'
 * en mobile -> '/mobile/casino'
 * ru mobile -> '/ru/mobile/casino'
 */
export function usePrefixRoute() {
  const { $i18n } = useNuxtApp()
  const { isMobile } = usePlatform()
  const localizeRoute = useLocaleRoute()
  const router = useRouter()

  function prefixRoute(
    to: RouteLocationRaw,
    options: PrefixRouteOptions = {},
  ): RouteLocationRaw {
    if (!to) return ''

    if (typeof to === 'string' && !to.startsWith('/')) return to

    let route

    try {
      route = router.resolve(to)
    } catch (error) {
      /* 
        router.resolve выпадает в ошибку на 404 странице
        если мы оказались здесь, то router.resolve пытался преобразовать query
        к несуществующему роуту ( ссылки на модалки )
        такие ссылки принудительно отправляем на главную страницу, где они будут открываться
      */
      if (typeof to === 'object')
        return {
          ...to,
          path: '/',
        }
    }
    if (!route) return ''

    // Если сслыка внешняя, мы попадаем сюда. У наших роутов всегда есть имя
    if (!route.name) return to

    let { fullPath } = route

    const hasLocalePrefix = $i18n.locales.value.some((locale) => {
      const localePrefix = typeof locale === 'string' ? locale : locale?.code
      return fullPath.startsWith(`/${localePrefix}`)
    })

    const hasMobilePrefix = fullPath.includes('/mobile')

    if (isMobile.value && !hasMobilePrefix) {
      fullPath = `/mobile${fullPath}`
    }

    if (!hasLocalePrefix) {
      fullPath = localizeRoute(fullPath, options.locale)?.fullPath ?? fullPath
    }

    return fullPath
  }

  return prefixRoute
}
