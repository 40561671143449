import { _createMinMaxBy } from '../_createMinMaxBy'

/**
 * This method is like Math.max except that it accepts iteratee
 * which is invoked for each element in array to generate the weight by which the element is ranked.
 *
 * @example
 * const users = [{ name: 'foo', age: 42 }, { name: 'bar', age: 66 }, { name: 'baz', age: 24 }]
 * maxBy(users, user => user.age) // { name: 'bar', age: 66 }
 */

export const maxBy = _createMinMaxBy('max')
