import { minBy, maxBy, groupBy } from '@st/utils'
import { useUserStore } from '@st/user/stores/useUserStore'
import { useFreespinsStore } from './useFreespinsStore'
import { useFreebetsStore } from './useFreebetsStore'
import type { DepositBonus, ExtendedDepositBonus } from '../types'

export const useDepositBonusesStore = defineStore('depositBonuses', () => {
  const stFetch = useRawStFetch()
  const { parseDate } = useDate()
  const bonuses = ref<DepositBonus[]>([])
  const { depositFreespins } = storeToRefs(useFreespinsStore())
  const { depositFreebets } = storeToRefs(useFreebetsStore())

  /**
   * все бонусы с groupName группируем для вывода в объедененной карточке
   * остальные группируются как default и выводятся по 1-ой карточке
   */
  const groupedByNameDepositBonuses = computed(() =>
    groupBy(bonuses.value, (bonus) => bonus.groupName ?? 'default'),
  )
  const defaultBonuses = computed(() => [
    ...groupedByNameDepositBonuses.value.get('default'),
  ])
  const stepBonuses = computed<ExtendedDepositBonus[]>(() => {
    const result: ExtendedDepositBonus[] = []
    groupedByNameDepositBonuses.value.forEach((groupBonuses, groupName) => {
      if (groupName === 'default') return

      /*
        из группы бонусов для отрисовки используется с Max weightInGroup
        minDepositAmount берется из Min weightInGroup
      */
      const lastStep = maxBy(groupBonuses, (item) => item.weightInGroup)
      const firstStep = minBy(groupBonuses, (item) => item.weightInGroup)
      if (!lastStep) return

      result.push({
        ...lastStep,
        steps: groupBonuses,
        minFromAmount: firstStep?.minDepositAmountInAppCurrency,
      })
    })

    return result
  })
  const allBonuses = computed(() =>
    stepBonuses.value.concat(defaultBonuses.value),
  )

  const bonusesCount = computed(
    () =>
      allBonuses.value.length +
      depositFreespins.value.length +
      depositFreebets.value.length,
  )
  const newBonuses = computed(() =>
    allBonuses.value.filter((item) => !item.bonus),
  )
  const favoriteNewDepositBonus = computed(() =>
    minBy(newBonuses.value, (bonus) => parseDate(bonus.expiredAt).unix()),
  )
  const isLoading = ref(false)

  async function fetchDepositBonuses() {
    try {
      isLoading.value = true
      const response = await stFetch('/bonus-for-deposit/find', {
        method: 'post',
        body: {
          params: {},
          pagination: {
            page: 0,
            perPage: 50,
            orderBy: [
              {
                fieldName: 'createdAt',
                sortOrder: 'DESC',
              },
            ],
          },
        },
      })
      bonuses.value = response.data as DepositBonus[]
    } catch (error) {
      console.error('Failed to load deposit bonuses', error)
    } finally {
      isLoading.value = false
    }
  }

  const router = useRouter()
  async function handleDepositModalShow() {
    if (!bonuses.value.length) {
      await fetchDepositBonuses()
    }

    if (favoriteNewDepositBonus.value) {
      router.replace({ query: { modal: 'depositBonus' } })
    }
  }

  const { isAuthenticated } = storeToRefs(useUserStore())
  watchEffect(() => {
    if (isAuthenticated.value) {
      fetchDepositBonuses()
    } else {
      bonuses.value = []
    }
  })

  return {
    bonuses: allBonuses,
    depositFreespins,
    depositFreebets,
    favoriteNewDepositBonus,
    bonusesCount,
    isLoading,
    handleDepositModalShow,
    fetchDepositBonuses,
  }
})
