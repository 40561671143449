import { type MaybeRefOrGetter, toValue } from 'vue'

type Unref<T> = T extends MaybeRefOrGetter<infer U> ? U : never

export function unrefObject<T extends Record<string, MaybeRefOrGetter<any>>>(
  source: T,
) {
  return Object.fromEntries(
    Object.entries(source).map(([key, value]) => [key, toValue(value)]),
  ) as { [P in keyof T]: Unref<T[P]> }
}
