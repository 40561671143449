export interface Rule {
  id: number
  sortOrder: number
  sbType: string
  fullMatchCompetitors?: boolean
  active?: boolean
  data: {
    sbId: number
    name?: string
    sbTreePath?: string
    comment?: string
  }[]
}

export interface Top {
  id: number
  name: string
  active: boolean
  rules: Rule[]
}

export type SbTopRules = Top[]

export type SbTopSportEvents = {
  type:
    | 'match'
    | 'tournament'
    | 'matchOfTheDay'
    | 'secondMatchOfTheDay'
    | 'cyberMatch'
  sbIds: number[]
  createdAt: string
}[]

export const useHomeStore = defineStore('home', () => {
  const sbTopSportEvents = ref<SbTopSportEvents>([])
  const sbTopRules = ref<SbTopRules>([])
  const sbTopSportEventsByType = computed(
    () =>
      Object.fromEntries(
        sbTopSportEvents.value.map(({ type, sbIds }) => [type, sbIds]),
      ) as Partial<Record<SbTopSportEvents[number]['type'], number[]>>,
  )

  const mainEventsIds = computed(
    () => sbTopSportEventsByType.value.matchOfTheDay ?? [],
  )
  const secondEventsIds = computed(
    () => sbTopSportEventsByType.value.secondMatchOfTheDay ?? [],
  )
  const customTournamentsIds = computed(
    () => sbTopSportEventsByType.value.tournament || [],
  )
  const cyberEventsOfTheDay = computed(
    () => sbTopSportEventsByType.value.cyberMatch || [],
  )

  function setSbTopSportEvents(value: SbTopSportEvents) {
    sbTopSportEvents.value = value
  }
  function setSbTopRules(value: SbTopRules) {
    sbTopRules.value = value
  }

  return {
    sbTopSportEvents,
    sbTopRules,
    mainEventsIds,
    secondEventsIds,
    customTournamentsIds,
    cyberEventsOfTheDay,
    setSbTopRules,
    setSbTopSportEvents,
  }
})
