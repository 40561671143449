const IndexedArray = new Proxy(Array, {
  construct(Target, [args]) {
    const indexId = {}
    const ids = new Set()
    const created = new Date()

    const addInIndex = (id, one) => {
      ids.add(id)
      if (!indexId[id]) {
        indexId[id] = one
      } else {
        if (Array.isArray(indexId[id])) {
          indexId[id].push(one)
        } else {
          indexId[id] = [indexId[id], one]
        }
      }
    }
    const removeFromIndex = (id, one) => {
      const elements = indexId[id]
      if (!elements) {
        return
      }

      if (Array.isArray(elements)) {
        const index = elements.findIndex((el) => el === one)
        elements.splice(index, 1)
        if (elements.length === 1) {
          indexId[id] = elements[0]
        } else {
          indexId[id] = elements
        }
      } else {
        delete indexId[id]
        ids.delete(id)
      }
    }

    for (let i = args.length - 1; i >= 0; i--) {
      const one = args[i]
      const id = one.id
      addInIndex(id, args[i])
    }

    return new Proxy(new Target(...args), {
      get(arr, prop) {
        switch (prop) {
          case 'created':
            return created
          case 'push':
            return (...items) => {
              items.forEach((item) => {
                addInIndex(item.id, item)
              })
              arr[prop].call(arr, ...items)
            }
          case 'splice':
            return (start, deleteCount, ...items) => {
              const deleted = arr[prop].call(arr, start, deleteCount, ...items)
              deleted.forEach((item) => {
                removeFromIndex(item.id, item)
              })
            }
          case 'findById':
            return (id) => {
              return indexId[id]
            }
          case 'findByIds':
            return (_ids = []) => {
              let _i = 0
              const _len = _ids.length
              let out = null
              while (_i < _len) {
                const _id = _ids[_i]
                if (ids.has(_id)) {
                  out = indexId[_id]
                  break
                }
                _i++
              }
              return out
            }
          default:
            return arr[prop]
        }
      },
    })
  },
})

export default IndexedArray
