/**
 *
 * @param array of objects.
 * @param cb name of key to Set.
 * @return  Returns new Set() of Object[field] values.
 *
 * @example
 * const users = [{ name: 'foo', age: 42, id: 390 }, { name: 'bar', age: 66, id: 790 }, { name: 'baz', age: 24, id: 900 }]
 * mapToSet(users, (item) => item.id ) // Set [390, 790, 900]
 *
 */
export function setBy<T, U>(
  array: T[],
  cb: (item: T, index: number, array: T[]) => U,
): Set<U> {
  return array.reduce((accumulator, currentObject, index) => {
    const currentObjectValue = cb(currentObject, index, array)
    if (currentObjectValue) {
      accumulator.add(currentObjectValue)
    }
    return accumulator
  }, new Set<U>())
}
