export default {
  "casino-game-layout": () => import("/app/apps/site/layouts/casino-game-layout.vue").then(m => m.default || m),
  default: () => import("/app/apps/site/layouts/default.vue").then(m => m.default || m),
  "empty-restriction": () => import("/app/apps/site/layouts/empty-restriction.vue").then(m => m.default || m),
  empty: () => import("/app/apps/site/layouts/empty.vue").then(m => m.default || m),
  "event-layout": () => import("/app/apps/site/layouts/event-layout.vue").then(m => m.default || m),
  "mobile-basic": () => import("/app/apps/site/layouts/mobile-basic.vue").then(m => m.default || m),
  "mobile-casino-game": () => import("/app/apps/site/layouts/mobile-casino-game.vue").then(m => m.default || m),
  "mobile-event": () => import("/app/apps/site/layouts/mobile-event.vue").then(m => m.default || m),
  "mobile-with-footer-without-tapbar": () => import("/app/apps/site/layouts/mobile-with-footer-without-tapbar.vue").then(m => m.default || m),
  "mobile-with-footer": () => import("/app/apps/site/layouts/mobile-with-footer.vue").then(m => m.default || m),
  mobile: () => import("/app/apps/site/layouts/mobile.vue").then(m => m.default || m),
  personal: () => import("/app/apps/site/layouts/personal.vue").then(m => m.default || m)
}